// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'

export const setLoading = createAsyncThunk('inventoryFieldsSlice/setLoading', async (loadingState) => ({ ...loadingState }))

export const getData = createAsyncThunk('inventoryFieldsSlice/getData', async (params, { dispatch }) => {
    dispatch(setLoading({ getData: true }))

    const response = await axios.get('/DynamicFields/List', { params })

    dispatch(setLoading({ getData: false }))

    return {
        params,
        data: response.data,
    }
})

export const inventoryFieldsSlice = createSlice({
    name: 'inventoryFieldsSlice',
    initialState: {
        data: [],
        totalRecords: 1,
        params: {},
        loadingState: { getData: false }
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data.data.list??[]
                state.totalRecords = action.payload.data.totalRecords
                state.params = action.payload.params
            })
            .addCase(setLoading.fulfilled, (state, action) => {
                state.loadingState = action.payload
            })
    }
})

export default inventoryFieldsSlice.reducer
