// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import {getAllSales} from "../../deshboard/store";

export const setLoading = createAsyncThunk('appPayments/setLoading', async (loadingState) => ({ ...loadingState }))

export const getData = createAsyncThunk('appPayments/getData', async (params, { dispatch }) => {
    let response;
    if(params.withBalance) {
        response = await axios.get('/Defaults/DetailCashBankCOAListWithBalances', {params})
    } else{
        response = await axios.get('/Defaults/DetailCashBankCOAList', {params})
    }
    return {
        params,
        data: response.data
    }
})


export const getTransactions = createAsyncThunk('appBRS/getTransactions', async (params, { dispatch }) => {
    dispatch(setLoading({ getTransactions: true }))
    let response = await axios.get(`/BRS/GetSystemAllTransactions`, { params })
    dispatch(setLoading({ getTransactions: false }))
    return {
        data: response.data,
        params
    }

})


export const getUnCategories = createAsyncThunk('appBRS/getUnCategories', async (params, { dispatch }) => {
    dispatch(setLoading({ getUnCategories: true }))
    let response = await axios.get(`/BRS/GetUnCategorizedTransactions`, { params })
    dispatch(setLoading({ getUnCategories: false }))
    return {
        data: response.data,
        params
    }

})

export const clearTransactions = createAsyncThunk('appBRS/clearTransactions', async () => {
    return []
})



export const getBanksChart = createAsyncThunk('FavStore/getBanksChart', async (params, { dispatch }) => {
    const response = await axios.get('/BRS/GetBRSBankChart', { params })
    return {
        params,
        data: response.data,
    }
})


export const getTransactionAccount = createAsyncThunk('appBRS/getTransactionAccount', async (recno, { dispatch }) => {
    dispatch(clearTransactions())
    const response = await axios.get(`/Defaults/DetailCashBankCOAList?Recno=${recno}`)
    return response.data
})

export const appBRS = createSlice({
    name: 'appBRS',
    initialState: {
        voucher: {},
        data: [],
        transactions: [],
        bank_chart: [],
        un_categories: [],
        account: null,
        totalRecords: 1,
        params: {},
        trans_params: {},
        bank_chart_params: {},
        loadingState: { getData: false, getVoucher: false, getTransactions: true, getUnCategories: true }
        // allData: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data.data
                state.params = action.payload.params
            })
            .addCase(getTransactionAccount.fulfilled, (state, action) => {
                state.account = action.payload.data.length ? action.payload.data[0] : null
            })
            .addCase(getTransactions.fulfilled, (state, action) => {
                state.transactions = action.payload.data.data.list
                state.totalRecords = action.payload.data.totalRecords
                state.trans_params = action.payload.params
            })
            .addCase(getUnCategories.fulfilled, (state, action) => {
                state.un_categories = action.payload.data.data.list
                state.totalUnRecords = action.payload.data.totalRecords
                state.un_cat_params = action.payload.params
            })
            .addCase(getBanksChart.fulfilled, (state, action) => {
                state.bank_chart = action.payload.data.data
                state.bank_chart_params = action.payload.params
            })
            .addCase(clearTransactions.fulfilled, (state, action) => {
                state.transactions = action.payload
                state.totalRecords = 0
                state.params = {}
            })
            .addCase(setLoading.fulfilled, (state, action) => {
                state.loadingState = action.payload
            })
    }
})

export default appBRS.reducer
