// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const setLoading = createAsyncThunk('TaxCodeDetailReport/setLoading', async (loadingState) => ({ ...loadingState }))

export const getDetailData = createAsyncThunk('TaxCodeDetailReport/getDetailData', async (params, { dispatch }) => {

    dispatch(setLoading({ getDetailData: true }))

    try {
            let response = await axios.get('/AccountsReports/GetTransactionDetailByTaxCodeReport', {params})

        return {
            params,
            data: response.data,
        }
    }catch (e){
        console.log(e);
        return {
            params,
            data: {data: {
                list: []
            }}
        }
    }finally {
        dispatch(setLoading({ getDetailData: false }))
    }

})

export const TaxCodeDetailReportDetail = createSlice({
    name: 'TaxCodeDetailReport',
    initialState: {
        data: [],
        totalRecords: 1,
        params: {},
        loadingState: { getDetailData: false }
        // allData: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDetailData.fulfilled, (state, action) => {
                state.data = action.payload.data.data;
                state.totalRecords = action.payload.data.totalRecords;
                state.params = action.payload.params;
            })
            .addCase(setLoading.fulfilled, (state, action) => {
                state.loadingState = action.payload;
            });
    },
})

export default TaxCodeDetailReportDetail.reducer
