import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const setLoading = createAsyncThunk("purchaseReport/setLoading", async (loadingState) => ({ ...loadingState }));


export const getData = createAsyncThunk("purchaseReport/getData", async (params, { dispatch }) => {
   dispatch(setLoading({ getData: true }));

   try {
      let response = await axios.get("/PurchaseReports/PurchaseWReturnList", { params });
      return {
         params,
         data: response.data,
      };
   } catch (e) {
      return {
         params,
         data: {
            data: {
               list: [],
            },
         },
      };
   } finally {
      dispatch(setLoading({ getData: false }));
   }
});

export const vendorBalances = createSlice({
   name: "purchaseReport",
   initialState: {
      data: [],
      totalRecords: 1,
      params: {},
      loadingState: { getData: false },
      // allData: []
   },
   reducers: {},
   extraReducers: (builder) => {
      builder
         .addCase(getData.fulfilled, (state, action) => {

            for(let i = 0; i < action.payload.data.data.list.length; i++){
               if (action.payload.data.data.list[i]["Invoice_Tax_Type"]==="Exclusive"){
                  let Amount = action.payload.data.data.list[i].Exclusive_Amount + (action.payload.data.data.list[i].Inclusive_Amount - action.payload.data.data.list[i].Exclusive_Amount);
                  action.payload.data.data.list[i].Amount = action.payload.data.data.list[i].Rate < 0 ? (-1 * Amount) : Amount;
               } else {
                  let Amount = action.payload.data.data.list[i].Inclusive_Amount;
                  action.payload.data.data.list[i].Amount = action.payload.data.data.list[i].Rate < 0 ? (-1 * Amount) : Amount;
               }
            }

            state.data = action.payload.data.data.list;
            state.totalRecords = action.payload.data.totalRecords;
            state.params = action.payload.params;
         })
         .addCase(setLoading.fulfilled, (state, action) => {
            state.loadingState = action.payload;
         });
   },
});

export default vendorBalances.reducer;
