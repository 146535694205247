// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// ** Axios Imports
import axios from "axios";

export const setLoading = createAsyncThunk("purchaseReturnPref/setLoading", async (loadingState) => ({ ...loadingState }));

export const getData = createAsyncThunk("purchaseReturnPref/getData", async (params, { dispatch }) => {
   dispatch(setLoading({ getData: true }));
   const response = await axios.get("/Prefrences/GetPurchaseReturnInvoicePrefrences", { params });
   const responseColumns = await axios.get('/Prefrences/GetPurchaseReturnInvoiceFormColumns', {})
   const responseCatColumns = await axios.get('/Prefrences/GetPurchaseReturnInvoiceAccountsFormColumns', {})

   dispatch(setLoading({ getData: false }));
   return {
      params,
      data: response.data,
      columns: responseColumns.data,
      cat_columns: responseCatColumns.data
   };
});

export const getCodeCustomization = createAsyncThunk("purchaseReturnPref/getCodeCustomization", async (params, { dispatch }) => {
   dispatch(setLoading({ getCodeCustomization: true }));
   const response = await axios.get("/Prefrences/GetDocumentCodesPrefrences", { params });

   dispatch(setLoading({ getCodeCustomization: false }));
   return {
      params,
      data: response.data,
   };
});

export const purchaseReturnPref = createSlice({
   name: "purchaseReturnPref",
   initialState: {
      data: [],
      columns: [],
      cat_columns: [],
      terms: "",
      notes: "",
      codeCustomization: {},
      totalRecords: 1,
      params: {},
      loadingState: { getData: false, getCodeCustomization: false },
   },
   reducers: {},
   extraReducers: (builder) => {
      builder
         .addCase(getData.fulfilled, (state, action) => {
            state.data = action.payload.data.data.list;
            state.columns = (action.payload.columns.data??[]).sort((a, b) => a.columnSeq - b.columnSeq)
            state.cat_columns = (action.payload.cat_columns.data??[]).sort((a, b) => a.columnSeq - b.columnSeq)
            state.terms = action.payload.data.data.terms;
            state.notes = action.payload.data.data.notes;
            state.totalRecords = action.payload.data.totalRecords;
            state.params = action.payload.params;
         })
         .addCase(getCodeCustomization.fulfilled, (state, action) => {
            state.codeCustomization = action.payload.data.data;
            state.params = action.payload.params;
         })
         .addCase(setLoading.fulfilled, (state, action) => {
            state.loadingState = action.payload;
         });
   },
});

export default purchaseReturnPref.reducer;
