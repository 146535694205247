// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'

export const setLoading = createAsyncThunk('SalesDeliveryNoteCustomization/setLoading', async (loadingState) => ({ ...loadingState }))

export const getData = createAsyncThunk('SalesDeliveryNoteCustomization/getData', async (params, { dispatch }) => {
    dispatch(setLoading({ getData: true }))
    const response = await axios.get('/InvoicePreviewCustomization/GetDeliveryNoteCustomization', { params })
    dispatch(setLoading({ getData: false }))
    return {
        params,
        data: response.data,
    }
})

export const SaleInvoiceCustomizationSlice = createSlice({
    name: 'SalesDeliveryNoteCustomization',
    initialState: {
        data: {},
        bank_data: {},
        params: {},
        loadingState: { getData: false }
        // allData: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data.data
                state.params = action.payload.params
            })
            .addCase(setLoading.fulfilled, (state, action) => {
                state.loadingState = action.payload
            })
    }
})

export default SaleInvoiceCustomizationSlice.reducer
