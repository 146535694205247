// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// ** Axios Imports
import axios from "axios";
import { fireMessage } from "../../../../components/MessageModel";

export const templateTabs = {
   SalesInvoice: { name: "Sales Invoice", uri: "/InvoicePreviewCustomization/GetSalesInvoicePreviewTemplates", default_uri: "/InvoicePreviewCustomization/GetDefaultSalesInvoicePreviewTemplates", post_uri: "/InvoicePreviewCustomization/AddSalesInvoicePreviewTemplate", templateType: "SalesInvoice" },
   SalesReturnInvoice: { name: "Sales Return Invoice", uri: "/InvoicePreviewCustomization/GetSalesReturnInvoicePreviewTemplates", default_uri: "/InvoicePreviewCustomization/GetDefaultSalesReturnInvoicePreviewTemplates", post_uri: "/InvoicePreviewCustomization/AddSalesReturnInvoicePreviewTemplate", templateType: "SalesReturnInvoice" },
   PurchaseInvoice: { name: "Bill", uri: "/InvoicePreviewCustomization/GetPurchaseInvoicePreviewTemplates", default_uri: "/InvoicePreviewCustomization/GetDefaltPurchaseInvoicePreviewTemplates", templateType: "PurchaseInvoice", post_uri: "/InvoicePreviewCustomization/AddPurchaseInvoicePreviewTemplate" },
   PurchaseReturnInvoice: { name: "Purchase Return Invoice", uri: "/InvoicePreviewCustomization/GetPurchaseReturnInvoicePreviewTemplates", default_uri: "/InvoicePreviewCustomization/GetDefaultPurchaseReturnInvoicePreviewTemplates", templateType: "PurchaseReturnInvoice", post_uri: "/InvoicePreviewCustomization/AddPurchaseReturnInvoicePreviewTemplate" },
   ExpenseInvoice: { name: "Expense", uri: "/InvoicePreviewCustomization/GetExpensesPreviewTemplates", default_uri: "/InvoicePreviewCustomization/GetDefaultExpensesPreviewTemplates", templateType: "ExpenseInvoice", post_uri: "/InvoicePreviewCustomization/AddExpensesPreviewTemplate" },
   GRNs: { name: "Goods Received Note", uri: "/InvoicePreviewCustomization/GetGRNPreviewTemplates", default_uri: "/InvoicePreviewCustomization/GetDefaultGRNPreviewTemplates", templateType: "GRNs", post_uri: "/InvoicePreviewCustomization/AddGRNPreviewTemplate" },
   DeliveryNotes: { name: "Delivery Notes", uri: "/InvoicePreviewCustomization/GetDeliveryNotesPreviewTemplates", default_uri: "/InvoicePreviewCustomization/GetDefaultDeliveryNotesPreviewTemplates", templateType: "DeliveryNotes", post_uri: "/InvoicePreviewCustomization/AddDeliveryNotesPreviewTemplate" },
   IssueToLocation: { name: "Issue To Location", uri: "/InvoicePreviewCustomization/GetITLPreviewTemplates", default_uri: "/InvoicePreviewCustomization/GetDefaultITLPreviewTemplates", templateType: "IssueToLocation", post_uri: "/InvoicePreviewCustomization/AddITLPreviewTemplate" },
   GoodsReceivedNoteReturn: { name: "Goods Received Note Return", uri: "/InvoicePreviewCustomization/GetGRNReturnPreviewTemplates", default_uri: "/InvoicePreviewCustomization/GetDefaultGRNReturnPreviewTemplates", templateType: "GoodsReceivedNote", post_uri: "/InvoicePreviewCustomization/AddGRNReturnPreviewTemplates" },
   PurchaseOrder: { name: "Purchase Order", uri: "/InvoicePreviewCustomization/GetPurchaseOrderPreviewTemplates", default_uri: "/InvoicePreviewCustomization/GetDefaultPurchaseOrderPreviewTemplates", templateType: "PurchaseOrder", post_uri: "/InvoicePreviewCustomization/AddPurchaseOrderPreviewTemplate" },
   PurchaseRequest: { name: "Purchase Request", uri: "/InvoicePreviewCustomization/GetPurchaseRequestPreviewTemplates", default_uri: "/InvoicePreviewCustomization/GetDefaultPurchaseRequestPreviewTemplates", post_uri: "/InvoicePreviewCustomization/AddPurchaseRequestPreviewTemplate", templateType: "PurchaseRequest" },
   Estimate: { name: "Estimate", uri: "/InvoicePreviewCustomization/GetSalesEstimatePreviewTemplates", default_uri: "/InvoicePreviewCustomization/GetDefaultSalesEstimatePreviewTemplates", post_uri: "/InvoicePreviewCustomization/AddSalesEstimatePreviewTemplate", templateType: "Estimate" },
};
export const setLoading = createAsyncThunk("templateStore/setLoading", async (loadingState) => ({ ...loadingState }));

export const setOpenEdit = createAsyncThunk("templateStore/setOpenEdit", async (loadingState) => ({ ...loadingState }));

export const getData = createAsyncThunk("templateStore/getData", async (params, { dispatch }) => {
   dispatch(setLoading({ getData: true }));
   let template = templateTabs[params?.template];
   let response = await axios.get(template.uri, { params });
   dispatch(setLoading({ getData: false }));
   return {
      params,
      data: response.data,
   };
});

export const getDefaultData = createAsyncThunk(`templateStore/getDefaultData`, async (params, { dispatch }) => {
   dispatch(setLoading({ getDefaultData: true }));
   let template = templateTabs[params?.template];
   let response = await axios.get(template.default_uri, { params });
   dispatch(setLoading({ getDefaultData: false }));
   return {
      params,
      data: response.data,
   };
});

export const addTemplate = createAsyncThunk("templateStore/addTemplate", async (params, { dispatch }) => {
   dispatch(setLoading({ addTemplate: true }));
   let template = templateTabs[params?.template];
   let response;
   let success = false;
   try {
      response = await axios.post(template.post_uri, params?.data);
      let saved_template;

      for (let j = 0; j < response.data.data.list.length; j++) {
         if (response.data.data.list[j].templateName === params.data.templateName) {
            saved_template = response.data.data.list[j];
         }
      }
      fireMessage("Template added successfully", "Success", "success");
      success = true;
      dispatch(getData(params));
      dispatch(setStoreSelectedTemplate({ id: saved_template.recno, data: saved_template }));
   } catch (e) {
      response = e.response || e;
      fireMessage(response.data.message || e, "Error", "error");
   } finally {
      dispatch(setLoading({ addTemplate: false }));
   }
   return success;
});

export const setStoreSelectedTemplate = createAsyncThunk("templateStore/setStoreSelectedTemplate", async (params, { dispatch }) => {
   dispatch(setOpenEdit({ openEdit: params?.data !== undefined }));

   return {
      params,
      data: params?.data,
   };
});

export const getLocation = createAsyncThunk("templateStore/getLocation", async (params, { dispatch }) => {
   dispatch(setLoading({ getLocation: true }));
   const locationValue = localStorage.getItem("locationData");
   const location = JSON.parse(locationValue);
   const response = await axios.get("/Defaults/LocationDetails?Recno=" + location.locationId, { params });
   dispatch(setLoading({ getLocation: false }));
   return {
      params,
      data: response.data,
   };
});

export const templateStoreSlice = createSlice({
   name: "templateStore",
   initialState: {
      employees: {},
      data: [],
      defaultData: [],
      locationInfo: {},
      totalRecords: 1,
      totalDefaultRecords: 1,
      params: {},
      selectedParams: {},
      defaultParams: {},
      locationParams: {},
      selectedTemplate: undefined,
      openEdit: false,
      loadingState: { getData: false, getDefaultData: false, addTemplate: false, getLocation: false },
      // allData: []
   },
   reducers: {},
   extraReducers: (builder) => {
      builder
         .addCase(getData.fulfilled, (state, action) => {
            state.data = action.payload.data.data.list;
            state.totalRecords = action.payload.data.totalRecords;
            state.params = action.payload.params;
         })
         .addCase(getDefaultData.fulfilled, (state, action) => {
            state.defaultData = action.payload.data.data.list;
            state.totalDefaultRecords = action.payload.data.totalRecords;
            state.defaultParams = action.payload.params;
         })
         .addCase(getLocation.fulfilled, (state, action) => {
            state.locationInfo = action.payload.data.data.length > 0 ? action.payload.data.data[0] : {};
            state.locationParams = action.payload.params;
         })
         .addCase(setStoreSelectedTemplate.fulfilled, (state, action) => {
            state.selectedTemplate = action.payload.data;
            state.selectedParams = action.payload.params;
         })
         .addCase(setOpenEdit.fulfilled, (state, action) => {
            state.openEdit = action.payload.openEdit;
         })
         .addCase(setLoading.fulfilled, (state, action) => {
            state.loadingState = action.payload;
         });
   },
});

export default templateStoreSlice.reducer;
