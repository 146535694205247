// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// ** Axios Imports
import axios from "axios";

export const setLoading = createAsyncThunk("vendorBalances/setLoading", async (loadingState) => ({ ...loadingState }));

export const getData = createAsyncThunk("vendorBalances/getData", async (params, { dispatch }) => {
   dispatch(setLoading({ getData: true }));

   try {
      let response;
      if (params.Location) {
         response = await axios.get("/AccountsReports/VendorBalances", { params });
      } else {
         response = await axios.get("/AccountsReports/VendorBalances", { params });
      }

      return {
         params,
         data: response.data,
      };
   } catch (e) {
      //   console.log(e);
      return {
         params,
         data: {
            data: {
               list: [],
            },
         },
      };
   } finally {
      dispatch(setLoading({ getData: false }));
   }
});

export const vendorBalances = createSlice({
   name: "vendorBalances",
   initialState: {
      data: [],
      totalRecords: 1,
      params: {},
      loadingState: { getData: false },
      // allData: []
   },
   reducers: {},
   extraReducers: (builder) => {
      builder
         .addCase(getData.fulfilled, (state, action) => {
            state.data = action.payload.data.data.list;
            state.totalRecords = action.payload.data.totalRecords;
            state.params = action.payload.params;
         })
         .addCase(setLoading.fulfilled, (state, action) => {
            state.loadingState = action.payload;
         });
   },
});

export default vendorBalances.reducer;
