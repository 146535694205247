// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'

export const setLoading = createAsyncThunk('customerInvoiceApprovals/setLoading', async (loadingState) => ({ ...loadingState }))

export const getData = createAsyncThunk('customerInvoiceApprovals/getData', async (params, { dispatch }) => {
    dispatch(setLoading({ getData: true }))

    const response = await axios.get('/UserRights/GetSalesInvoiceApproveableLvl1USersList', { params })
    const responseVeri = await axios.get('/UserRights/GetSalesInvoiceApproveableLvl2USersList', { params })
    const responseLv3 = await axios.get('/UserRights/GetSalesInvoiceApproveableLvl3USersList', { params })
    const responseLv4 = await axios.get('/UserRights/GetSalesInvoiceApproveableLvl4USersList', { params })
    const allUsers = await axios.get('/Defaults/GetOrganizationUsers', { params })

    dispatch(setLoading({ getData: false }))

    return {
        params,
        data: response.data,
        veriData: responseVeri.data,
        lv3Data: responseLv3.data,
        lv4Data: responseLv4.data,
        allUsers: allUsers.data,
    }
})

export const getApprovalData = createAsyncThunk('customerInvoiceApprovals/getApprovalData', async (params, { dispatch }) => {
    dispatch(setLoading({ getData: true }))

    const response = await axios.get('/Settings/SelectOrganizationApprovalSettings/Sales Invoice', { params })

    dispatch(setLoading({ getData: false }))

    return {
        params,
        data: response.data,
    }
})

export const customerInvoiceApprovals = createSlice({
    name: 'customerInvoiceApprovals',
    initialState: {
        approval_data: {},
        approvers: [],
        verifiers: [],
        lv3Users: [],
        lv4Users: [],
        users: [],
        totalRecords: 1,
        params: {},
        loadingState: { getData: false, getCodeCustomization: false }
        // allData: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.approvers = action.payload.data.data
                state.verifiers = action.payload.veriData.data
                state.lv3Users = action.payload.lv3Data.data
                state.lv4Users = action.payload.lv4Data.data
                state.users = action.payload.allUsers.data
                state.totalRecords = action.payload.data.totalRecords
                state.params = action.payload.params
            })
            .addCase(getApprovalData.fulfilled, (state, action) => {
                state.approval_data = action.payload.data.data
                state.params = action.payload.params
            })
            .addCase(setLoading.fulfilled, (state, action) => {
                state.loadingState = action.payload
            })
    }
})

export default customerInvoiceApprovals.reducer
