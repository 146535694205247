// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'

export const setLoading = createAsyncThunk('StockReport/setLoading', async (loadingState) => ({ ...loadingState }))

export const getData = createAsyncThunk('StockReport/getData', async (params, { dispatch }) => {
    dispatch(setLoading({ getData: true }))
    const response = await axios.get('/StockReports/StockMovement', { params })
    dispatch(setLoading({ getData: false }))
    return {
        params,
        data: response.data
    }
})


export const getDataLocation = createAsyncThunk('StockReport/getDataLocation', async (params, { dispatch }) => {
    dispatch(setLoading({ getData: true }))
    const response = await axios.get('/StockReports/StockMovementLocation', { params })
    dispatch(setLoading({ getData: false }))
    return {
        params,
        data: response.data
    }
})

export const StockReportSlice = createSlice({
    name: 'StockReport',
    initialState: {
        data: [],
        totalRecords: 1,
        params: {},
        loadingState: { getData: false}
        // allData: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data.data.list
                state.totalRecords = action.payload.data.totalRecords
                state.params = action.payload.params
            })
            .addCase(getDataLocation.fulfilled, (state, action) => {
                state.data = action.payload.data.data.list
                state.totalRecords = action.payload.data.totalRecords
                state.params = action.payload.params
            })
            .addCase(setLoading.fulfilled, (state, action) => {
                state.loadingState = action.payload
            })
    }
})

export default StockReportSlice.reducer
