import { Fragment } from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {useTranslation} from "react-i18next";

const MySwal = withReactContent(Swal)

export const fireMessage = async (message, title = "Message", icon, props = {}) => {

    if(typeof message !== 'string'){
        message = message?.response?.data?.message || message?.data?.message || message?.message || message
    }

    if(icon === 'error'){
        icon = 'warning'
    }

    if(title.toLowerCase().includes('error')){
        title = title.replaceAll('Error' , 'Warning')
        title = title.replaceAll('ERROR' , 'WARNING')
        title = title.replaceAll('error' , 'warning')
    }
    return await MySwal.fire({
        icon,
        title,
        html: message,
        // footer: '<a href="#">Why do I have this issue?</a>',
        customClass: {
            confirmButton: 'btn btn-primary mx-1',
            cancelButton: 'btn btn-danger mx-1'
        },
        buttonsStyling: false,
        confirmButtonText: props.t ? props.t("Close") : "Close",
        ...props
    })

}

const MessageModal = ({ visible, setVisible, message, title = "Message", ...props}) => {

    const {t} = useTranslation();

    const hideModel = () => {
        setVisible(false)
        if(props.onCickAction){
            props.onCickAction();
        }
    }

    const renderModal = (
        <Fragment >
            <Modal
                isOpen={!!visible}
                toggle={hideModel}
                className={'modal-dialog-centered'}
            >
                <ModalHeader toggle={hideModel}>
                    {title}
                </ModalHeader>
                <ModalBody>
                    {message}
                </ModalBody>
                <ModalFooter>
                    <Button color='primary' onClick={hideModel} outline>
                        {t(props.buttonText || "OK")}
                    </Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    )

    return <div className='demo-inline-spacing'>{renderModal}</div>
}
export default MessageModal