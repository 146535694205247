// ** Redux Imports
import {
    createSlice
    , createAsyncThunk
} from '@reduxjs/toolkit'
import axios from 'axios'

export const getNotifications = createAsyncThunk('notification/getNotifications', async () => {
    const response = await axios.get('/Login/GetNotifications?NotificationType=Notification&PageNo=1&PageSize=50')
    return {data: response?.data?.data?.list??[]}
})

export const getAnnouncement = createAsyncThunk('notification/getAnnouncement', async () => {
    const response = await axios.get('/Login/GetNotifications?NotificationType=Announcement&PageNo=1&PageSize=50')
    return {data: response?.data?.data?.list??[]}
})

export const notificationSlice = createSlice({
    name: 'notification',
    initialState: {
        notifications: [],
        announcement: [],
    },
    reducers: {
    },
    extraReducers: builder => {
        builder
            .addCase(getNotifications.fulfilled, (state, action) => {
                state.notifications = action.payload.data
            })
            .addCase(getAnnouncement.fulfilled, (state, action) => {
                state.announcement = action.payload.data
            });
    }

})

export default notificationSlice.reducer
