// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'

export const setLoading = createAsyncThunk('customerInvoiceEmail/setLoading', async (loadingState) => ({ ...loadingState }))

export const getData = createAsyncThunk('customerInvoiceEmail/getData', async (params, { dispatch }) => {
    dispatch(setLoading({ getData: true }))
    const response = await axios.get('/Prefrences/GetSalesInvoiceEmailTemplate', { params })

    console.log("responseee",response)

    dispatch(setLoading({ getData: false }))
    return {
        params,
        data: response.data
    }
})

export const customerInvoiceEmail = createSlice({
    name: 'customerInvoiceEmail',
    initialState: {
        data: {},
        params: {},
        loadingState: { getData: false }
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data.data
                state.params = action.payload.params
            })
            .addCase(setLoading.fulfilled, (state, action) => {
                state.loadingState = action.payload
            })
    }
})

export default customerInvoiceEmail.reducer
