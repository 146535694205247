// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// ** Axios Imports
import axios from "axios";

export const setLoading = createAsyncThunk("ExpenseCustomization/setLoading", async (loadingState) => ({ ...loadingState }));

export const getData = createAsyncThunk("ExpenseCustomization/getData", async (params, { dispatch }) => {
   dispatch(setLoading({ getData: true }));
   const response = await axios.get("/InvoicePreviewCustomization/GetExpensesCustomization", { params });
   dispatch(setLoading({ getData: false }));
   return {
      params,
      data: response.data,
   };
});

export const getBank = createAsyncThunk("ExpenseCustomization/getBank", async (params, { dispatch }) => {
   dispatch(setLoading({ getData: true }));
   const response = await axios.get("/Locations/GetBankDetails", { params });
   dispatch(setLoading({ getData: false }));
   return {
      params,
      data: response.data,
   };
});

export const ExpenseCustomizationSlice = createSlice({
   name: "ExpenseCustomization",
   initialState: {
      data: {},
      bank_data: {},
      params: {},
      loadingState: { getData: false },
      // allData: []
   },
   reducers: {},
   extraReducers: (builder) => {
      builder
         .addCase(getData.fulfilled, (state, action) => {
            state.data = action.payload.data.data;
            state.params = action.payload.params;
         })
         .addCase(getBank.fulfilled, (state, action) => {
            state.bank_data = action.payload.data.data;
            state.params = action.payload.params;
         })
         .addCase(setLoading.fulfilled, (state, action) => {
            state.loadingState = action.payload;
         });
   },
});

export default ExpenseCustomizationSlice.reducer;
