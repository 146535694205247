// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'

export const setLoading = createAsyncThunk('appPurchaseReturn/setLoading', async (loadingState) => ({ ...loadingState }))

export const getData = createAsyncThunk('appPurchaseReturn/getData', async (params, { dispatch }) => {
  dispatch(setLoading({ getData: true }))

  const responseConsolidated = await axios.get('/PurchaseReturnInvoices/ConsolidatedList', { params })

  dispatch(setLoading({ getData: false }))
  return {
    params,
    data: {data: {list: [], totalRecords: 0}},
    consolidated: responseConsolidated.data
  }
})

export const getTypeData = createAsyncThunk('appPurchaseReturn/getTypeData', async (params, { dispatch }) => {
  dispatch(setLoading({ getTypeData: true }))
  const response = await axios.get('/JVTypes/List', { params })
  dispatch(setLoading({ getTypeData: false }))
  return {
    params,
    data: response.data
  }
})

export const getJournal = createAsyncThunk('appPurchaseReturn/getJournal', async (recno, { dispatch }) => {
  dispatch(setLoading({ getJournal: true }))
  const response = await axios.get(`/PurchaseInvoice/${recno}`)
  dispatch(setLoading({ getJournal: false }))

  return response.data
})

export const deleteInvoice = createAsyncThunk('appPurchaseReturn/deleteInvoice', async (id, { dispatch, getState }) => {
  dispatch(setLoading({ deleteInvoice: true }))
  await axios.delete('/apps/invoice/delete', { id })
  await dispatch(getData(getState().invoice.params))
  dispatch(setLoading({ deleteInvoice: false }))
  return id
})

export const appPurchaseReturnSlice = createSlice({
  name: 'appPurchaseReturn',
  initialState: {
    returnOutwards: {},
    data: [],
    totalRecords: 1,
    typeData: [],
    totalTypeRecords: 1,
    params: {},
    typeParams: {},
    loadingState: { getData: false, getTypeData: false, getJournal: false }
    // allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        // console.log(state, action)
        state.data = action.payload.data.data.list
        state.consolidated = action.payload.consolidated.data.list
        state.totalRecords = action.payload.data.totalRecords
        state.consolidatedTotalRecords = action.payload.consolidated.totalRecords
        state.params = action.payload.params
      })
      .addCase(getTypeData.fulfilled, (state, action) => {
        state.typeData = action.payload.data.data.list
        state.totalRecords = action.payload.data.totalRecords
        state.typeParams = action.payload.params
      })
      .addCase(getJournal.fulfilled, (state, action) => {
        state.journal = action.payload
      })
      .addCase(setLoading.fulfilled, (state, action) => {
        state.loadingState = action.payload
      })
  }
})

export default appPurchaseReturnSlice.reducer
