import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const setLoading = createAsyncThunk("bankLedgerReport/setLoading", async (loadingState) => ({ ...loadingState }));


export const getData = createAsyncThunk("bankLedgerReport/getData", async (params, { dispatch }) => {
    dispatch(setLoading({ getData: true }));

    try {
        let response = await axios.get(`/AccountsReports/BankJournalLedgerSummaryCOALocationList`, {params});
        return {
            params,
            data: response.data,
        };
    } catch (e) {
        return {
            params,
            data: {
                data: {
                    list: [],
                },
            },
        };
    } finally {
        dispatch(setLoading({ getData: false }));
    }
});

export const getLedgerData = createAsyncThunk("bankLedgerReport/getLedgerData", async (params, { dispatch }) => {
    dispatch(setLoading({ getLedgerData: true }));

    try {
        let response = await axios.get(`/AccountsReports/BankJournalLedgerCOALocationList`, {params});
        return {
            params,
            data: response.data,
        };
    } catch (e) {
        return {
            params,
            data: {
                data: {
                    list: [],
                },
            },
        };
    } finally {
        dispatch(setLoading({ getLedgerData: false }));
    }
});

export const bankLedgerReport = createSlice({
    name: "bankLedgerReport",
    initialState: {
        data: [],
        ledgerData: [],
        totalRecords: 1,
        params: {},
        ledgerParams: {},
        loadingState: { getData: false , getLedgerData: false},
        // allData: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data.data.list;
                state.totalRecords = action.payload.data.totalRecords;
                state.params = action.payload.params;
            })
            .addCase(getLedgerData.fulfilled, (state, action) => {
                state.ledgerData = action.payload.data.data.list;
                state.totalRecords = action.payload.data.totalRecords;
                state.ledgerParams = action.payload.params;
            })
            .addCase(setLoading.fulfilled, (state, action) => {
                state.loadingState = action.payload;
            });
    },
});

export default bankLedgerReport.reducer;
