// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const setLoading = createAsyncThunk('tickets/setLoading', async (loadingState) => ({ ...loadingState }))

export const getData = createAsyncThunk('tickets/getData', async (params, { dispatch }) => {
    dispatch(setLoading({ getData: true }))
    const response = await axios.get('/Tickets/TicketsList', params)
    dispatch(setLoading({ getData: false }))
    return {
        params,
        data: response.data.data.list,
    }
})

export const getStatus = createAsyncThunk('tickets/getStatus', async (params, { dispatch }) => {
    dispatch(setLoading({ getData: true }))
    const response = await axios.get('/Defaults/GetTicketStatuses', params)
    dispatch(setLoading({ getData: false }))
    return {
        params,
        getStatus: response.data.data,
    }
})

export const getchat = createAsyncThunk('tickets/getchat', async (recno, { dispatch }) => {
    dispatch(setLoading({ getData: true }))
    const response = await axios.get(`/Tickets/GetTicketThreads/${recno}`)
    dispatch(setLoading({ getData: false }))
    return {
        params,
        getmsgs: response.data.data,
    }
})

export const getPriorityList = createAsyncThunk('tickets/getPriorityList', async (params, { dispatch }) => {
    dispatch(setLoading({ getPriorityList: true })) // Updated action type
    const response = await axios.get('/Defaults/GetPriorityTypes', params)
    dispatch(setLoading({ getPriorityList: false })) // Updated action type
    return {
        params,
        priorityList: response.data.data,
    }
})

export const fetchDetailData = createAsyncThunk('tickets/fetchDetailData', async (recno, { dispatch }) => { // Updated action type
    dispatch(setLoading({ fetchDetailData: true })) // Updated action type
    const response = await axios.get(`/Tickets/GetSingleTicketDetails/${recno}`)
    dispatch(setLoading({ fetchDetailData: false }))
    return {
        ticketDetail: response.data.data,
    }
})

export const appInvoiceSlice = createSlice({
    name: 'tickets',
    initialState: {
        data: [],
        priorityList: [],
        ticketDetail: [],
        getmsgs:[],
        getStatus:[],
        loadingState: { getData: false, getPriorityList: false, fetchDetailData: false }, // Updated action types
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getPriorityList.fulfilled, (state, action) => {
            state.priorityList = action.payload.priorityList
        })
        builder.addCase(fetchDetailData.fulfilled, (state, action) => {
            state.ticketDetail = action.payload.ticketDetail
        })
        builder.addCase(getchat.fulfilled, (state, action) => {
            state.getmsgs = action.payload.getmsgs
        })
        builder.addCase(getStatus.fulfilled, (state, action) => {
            state.getStatus = action.payload.getStatus
        })
            .addCase(setLoading.fulfilled, (state, action) => {
                state.loadingState = { ...state.loadingState, ...action.payload }
            })
        builder.addCase(getData.fulfilled, (state, action) => {
            state.data = action.payload.data
        })

    }
})

export default appInvoiceSlice.reducer
