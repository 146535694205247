// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import {groupBy} from "../../../../utility/Utils";

export const setLoading = createAsyncThunk('appPayments/setLoading', async (loadingState) => ({ ...loadingState }))

export const getData = createAsyncThunk('permissions/getData', async (params, {dispatch}) => {
  dispatch(setLoading({ getData: true }))

  const response = await axios.get('/UserRights/GetPoliciesList', { params })

  dispatch(setLoading({ getData: false }))
  return {
    total: response.data.totalRecords,
    data: response.data.data.list
  }
})

export const getRights = createAsyncThunk('permissions/getRights', async (params, {dispatch}) => {
  dispatch(setLoading({ getRights: true }))

  const response = await axios.get(`/UserRights/GetPolicyRights/${params.PolicyId}/${params.LocationId}/${params.FyearId}`, { params })

  dispatch(setLoading({ getRights: false }))
  return {
    total_rights: response.data.totalRecords,
    rights: response.data.data.list,
    params_rights: params,
  }
})

export const getPolicyUsers = createAsyncThunk('permissions/getPolicyUsers', async (params, {dispatch}) => {
  dispatch(setLoading({ getUsers: true }))

  const response = await axios.get(`/UserRights/GetOrgUsersDetails`, { params })

  dispatch(setLoading({ getUsers: false }))
  return {
    total_users: response.data.totalRecords,
    policyUsers: response.data.data,
    params_policy: params,
  }
})

export const assignUser = createAsyncThunk('permissions/assignUser', async (params, {dispatch}) => {
  dispatch(setLoading({ assignUser: true }))

  const response = await axios.post(`/UserRights/AddPolicyUsers`, params )

  dispatch(setLoading({ assignUser: false }))
  return response.data;
})

export const addPermission = createAsyncThunk(
    'permissions/addPermission',
    async (permission, { dispatch, getState }) => {
      await axios.post('/apps/permissions/add-permission', { permission })
      await dispatch(getData(getState().permissions.params))
      return permission
    }
)

export const addPolicy = createAsyncThunk(
    'permissions/addPolicy',
    async (policy, { dispatch }) => {
      await axios.post('/UserRights/AddPolicy',  policy.data)
      await dispatch(getData(policy.params))
      return policy
    }
)

export const updatePermission = createAsyncThunk(
    'permissions/updatePermission',
    async (params, { dispatch, state }) => {
        try {
            let res = await axios.post('/UserRights/AddPolicyRights', params.data)
            await dispatch(getData(params.state))
            return {
                data: res.data,
                params
            };
        } catch(e) {
            return {
                data: e.response,
                params
            };
        }
    }
)

export const updateRights = createAsyncThunk(
    'permissions/updateRights',
    async (params, { dispatch}) => {
      return params;
    }
)

export const bulkUpdateRights = createAsyncThunk(
    'permissions/bulkUpdateRights',
    async (params, { dispatch}) => {
      return params;
    }
)

export const deletePermission = createAsyncThunk('permissions/deletePermission', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/permissions/delete', { id })
  await dispatch(getData(getState().permissions.params))
  return id
})

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState: {
    data: [],
    rights: [],
    policyUsers: [],
    total: 1,
    params: {},
    update_res: {},
    allData: [],
    selected: null,
    loadingState: { getData: false, getRights: false, updateRights: false, getUsers: false}
  },
  reducers: {
    selectPermission: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    }
  },
  extraReducers: builder => {
    builder
        .addCase(getData.fulfilled, (state, action) => {
          state.data = action.payload.data
          state.total = action.payload.total
          state.params = action.payload.params
          state.allData = action.payload.allData
        })
        .addCase(getRights.fulfilled, (state, action) => {
            let grouping = groupBy(action.payload.rights, 'screenGroup');
            let allRights = [];

            Object.keys(grouping).forEach((key) => {
                let viewScreen = true;
                let addScreen = true;
                let updateScreen = true;
                let deleteScreen = true;

                for(let i = 0 ; i < grouping[key].length; i++) {
                    if(!grouping[key][i].viewScreen) {
                        viewScreen = false;
                    }

                    if(!grouping[key][i].addScreen) {
                        addScreen = false;
                    }

                    if(!grouping[key][i].updateScreen) {
                        updateScreen = false;
                    }

                    if(!grouping[key][i].deleteScreen) {
                        deleteScreen = false;
                    }
                }
                allRights.push({
                    policyId: grouping[key][0].policyId,
                    locationId: grouping[key][0].locationId,
                    screenName: grouping[key][0].screenGroup,
                    screenDescription: grouping[key][0].screenGroup,
                    screenGroup: grouping[key][0].screenGroup,
                    viewScreen: viewScreen,
                    addScreen: addScreen,
                    updateScreen: updateScreen,
                    deleteScreen: deleteScreen,
                    list: grouping[key]
                })
            })

          state.rights = allRights
          state.total_rights = action.payload.total_rights
          state.params_rights = action.payload.params_rights
        })
        .addCase(updatePermission.fulfilled, (state, action) => {
          state.update_res = action.payload.data
        })
        .addCase(getPolicyUsers.fulfilled, (state, action) => {
          state.policyUsers = action.payload.policyUsers
          state.total_users = action.payload.total_users
          state.params_users = action.payload.params_users
        })
        .addCase(updateRights.fulfilled, (state, action) => {
          state.rights = state.rights.map((e, index) => {
              if(index === action.payload.index){
                  let viewScreen = true;
                  let addScreen = true;
                  let updateScreen = true;
                  let deleteScreen = true;
                  e.list = e.list.map((f, subIndex) => {
                      if(subIndex === action.payload.subIndex) {
                          if(!action.payload.viewScreen) {
                              viewScreen = false;
                          }

                          if(!action.payload.addScreen) {
                              addScreen = false;
                          }

                          if(!action.payload.updateScreen) {
                              updateScreen = false;
                          }

                          if(!action.payload.deleteScreen) {
                              deleteScreen = false;
                          }
                      } else {
                          if(!f.viewScreen) {
                              viewScreen = false;
                          }

                          if(!f.addScreen) {
                              addScreen = false;
                          }

                          if(!f.updateScreen) {
                              updateScreen = false;
                          }

                          if(!f.deleteScreen) {
                              deleteScreen = false;
                          }
                      }
                    return subIndex === action.payload.subIndex ? action.payload : f;
                  });
                  e.viewScreen = viewScreen;
                  e.addScreen = addScreen;
                  e.updateScreen = updateScreen;
                  e.deleteScreen = deleteScreen;
                return e;
              } else {
                  return e;
              }
          })
        })
        .addCase(bulkUpdateRights.fulfilled, (state, action) => {
          state.rights = state.rights.map((e, index) => {
              return index === action.payload.index ? action.payload : e
          })
        })
  }
})

export const { selectPermission } = permissionsSlice.actions

export default permissionsSlice.reducer