// ** Core JWT Import
import * as service from '@api/api.service'
import useJwt from '@src/@core/auth/jwt/useJwt'

const config = {
    loginEndpoint: service.API_LOGIN,
    registerEndpoint: service.API_REGISTER,
    forgotPasswordEndPoint: service.API_FORGOT_PASSWORD,
    forgotPasswordResetEndPoint: service.API_FORGOT_PASSWORD_RESET,
    forgotPasswordResetSubmitEndPoint: service.API_FORGOT_PASSWORD_RESET_SUBMIT,
    refreshEndpoint: '/jwt/refresh-token',
    logoutEndpoint: '/jwt/logout',

    // ** This will be prefixed in authorization header with token
    // ? e.g. Authorization: Bearer <token>
    tokenType: 'Bearer',

    // ** Value of this property will be used as key to store JWT token in storage
    storageTokenKeyName: 'accessToken',
    storageRefreshTokenKeyName: 'refreshToken'
}

const { jwt } = useJwt(config) // eslint-disable-line

export default jwt
