// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import {getTotalTrialBalance} from "../../../../utility/Utils";

export const setLoading = createAsyncThunk('Aging/setLoading', async (loadingState) => ({ ...loadingState }))

export const getData = createAsyncThunk('Aging/getData', async (params, { dispatch }) => {
    dispatch(setLoading({ getData: true }))
    try {
        let response = await axios.get('/PurchaseReports/GetVendorsAgingList', {params})
        return {
            params,
            data: response.data,
        }
    }catch (e){
        console.log(e);
        return {
            params,
            data: {data: {
                list: []
            }}
        }
    }finally {
        dispatch(setLoading({ getData: false }))
    }

})


export const getDetailData = createAsyncThunk('Aging/getDetailData', async (params, { dispatch }) => {
    dispatch(setLoading({ getDetailData: true }))
    try {
        let response = await axios.get('/PurchaseReports/GetVendorWiseAgingList', {params})
        return {
            params,
            data: response.data,
        }
    }catch (e){
        console.log(e);
        return {
            params,
            data: {data: {
                list: []
            }}
        }
    }finally {
        dispatch(setLoading({ getDetailData: false }))
    }

})

export const deleteInvoice = createAsyncThunk('Aging/deleteInvoice', async (id, { dispatch, getState }) => {
    dispatch(setLoading({ deleteInvoice: true }))
    await axios.delete('/apps/invoice/delete', { id })
    await dispatch(getData(getState().invoice.params))
    dispatch(setLoading({ deleteInvoice: false }))
    return id
})

export const AgingStore = createSlice({
    name: 'Aging',
    initialState: {
        data: [],
        data_detail: [],
        totalRecords: 1,
        totalRecordsDetail: 1,
        params: {},
        loadingState: { getData: false, getDetailData: false }
        // allData: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data.data.list
                state.totalRecords = action.payload.data.data.totalRecords
                state.params = action.payload.params
            })
            .addCase(getDetailData.fulfilled, (state, action) => {
                state.data_detail = action.payload.data.data.list
                state.totalRecordsDetail = action.payload.data.data.totalRecords
                state.params = action.payload.params
            })
            .addCase(setLoading.fulfilled, (state, action) => {
                state.loadingState = action.payload
            })
    }
})

export default AgingStore.reducer
