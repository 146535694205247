import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'


export const setLoading = createAsyncThunk('projectInvoiceDetail/setLoading', async (loadingState) => ({ ...loadingState }))

export const getData = createAsyncThunk('projectInvoiceDetail/getData', async (params, { dispatch }) => {
    if(params?.projectId) {
        dispatch(setLoading({getData: true}))
        try {
            let response;
            if (params.overdue) {
                response = await axios.get(`/Projects/GetProjectOverDueInvoices/${params?.projectId}`, {params})
            } else {
                response = await axios.get(`/Projects/GetProjectOpenInvoices/${params?.projectId}`, {params})
            }
            return {
                params,
                data: response.data,
            }
        } catch (e) {
            console.log(e);
            return {
                params,
                data: {
                    data: {
                        list: []
                    }
                }
            }
        } finally {
            dispatch(setLoading({getData: false}))
        }
    } else {
        return {
            params,
            data: {
                data: {
                    list: []
                }
            }
        }
    }

})



export const projectInvoiceDetail = createSlice({
    name: 'projectInvoiceDetail',
    initialState: {
        data: [],
        totalRecords: 1,
        params: {},
        loadingState: { getData: false }
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data.data.list;
                state.totalRecords = action.payload.data.totalRecords;
                state.params = action.payload.params;
            })
            .addCase(setLoading.fulfilled, (state, action) => {
                state.loadingState = action.payload;
            });
    },
})

export default projectInvoiceDetail.reducer
