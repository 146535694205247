// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'

export const setLoading = createAsyncThunk('reportingTags/setLoading', async (loadingState) => ({ ...loadingState }))

export const getData = createAsyncThunk("reportingTags/getData", async (params, { dispatch }) => {
    dispatch(setLoading({ getData: true }));
    const response = await axios.get("/ReportingTags/SelectReportingTagsList", { params });

    dispatch(setLoading({ getData: false }));
    return {
        params,
        data: response.data,
    };
});

export const getDetailData = createAsyncThunk("reportingTags/getDetailData", async (recno, { dispatch }) => {
    dispatch(setLoading({ getDetailData: true }));
    const response = await axios.get(`/ReportingTags/SelectSingleReportingTag/${recno}`);

    dispatch(setLoading({ getDetailData: false }));
    return {
        data: response.data,
    };
});

export const reportingTagsSlice = createSlice({
    name: 'reportingTags',
    initialState: {
        data: [],
        data_detail: {},
        params: {},
        loadingState: { getData: false, getDetailData: false}
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload?.data?.data?.list
                state.totalRecords = action.payload.data.totalRecords
                state.params = action.payload.params
            })
            .addCase(getDetailData.fulfilled, (state, action) => {
                state.data_detail = action.payload?.data?.data
            })
            .addCase(setLoading.fulfilled, (state, action) => {
                state.loadingState = action.payload
            })
    }
})

export default reportingTagsSlice.reducer
