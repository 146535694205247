// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'

export const setLoading = createAsyncThunk('FavStore/setLoading', async (loadingState) => ({ ...loadingState }))

export const getData = createAsyncThunk('FavStore/getData', async (params, { dispatch }) => {
    dispatch(setLoading({ getData: true }))
    const response = await axios.get('/UserFavourites/GetFavouritesList', { params })
    dispatch(setLoading({ getData: false }))
    return {
        params,
        data: response.data,
    }
})

export const getRevenueStats = createAsyncThunk('FavStore/getRevenueStats', async (params, { dispatch }) => {
    const response = await axios.get('/Dashboard/GetRevenueStats', { params })
    return {
        params,
        data: response.data,
    }
})

export const getPaymentPurchaseStats = createAsyncThunk('FavStore/getPaymentPurchaseStats', async (params, { dispatch }) => {
    const response = await axios.get('/Dashboard/GetPurchasePaymentStats', { params })
    return {
        params,
        data: response.data,
    }
})

export const getProfitAndLoss = createAsyncThunk('FavStore/getProfitAndLoss', async (params, { dispatch }) => {
    dispatch(setLoading({ getProfit: true }))
    const response = await axios.get('/Dashboard/GetProfitAndLossDetails', { params })
    dispatch(setLoading({ getProfit: false }))
    return {
        params,
        data: response.data,
    }
})

export const getTotalSales = createAsyncThunk('FavStore/getTotalSales', async (params, { dispatch }) => {
    const response = await axios.get('/Dashboard/GetSalesStats', { params })
    return {
        params,
        data: response.data,
    }
})

export const getMonthlyPurchases = createAsyncThunk('FavStore/getMonthlyPurchases', async (params, { dispatch }) => {
    const response = await axios.get('/Dashboard/GetPurchaseStats', { params })
    return {
        params,
        data: response.data,
    }
})

export const getPrevMonthlyPurchases = createAsyncThunk('FavStore/getPrevMonthlyPurchases', async (params, { dispatch }) => {
    const response = await axios.get('/Dashboard/GetPurchaseStats', { params })
    return {
        params,
        data: response.data,
    }
})

export const getTotalRecoveriesStats = createAsyncThunk('FavStore/getTotalRecoveriesStats', async (params, { dispatch }) => {
    const response = await axios.get('/Dashboard/GetTotalRecoveriesStats', { params })
    return {
        params,
        data: response.data,
    }
})

export const getMonthlyExpenses = createAsyncThunk('FavStore/getMonthlyExpenses', async (params, { dispatch }) => {
    const response = await axios.get('/Dashboard/GetTypeWiseExpensesStats', { params })
    return {
        params,
        data: response.data,
    }
})

export const getPeriodSales = createAsyncThunk('FavStore/getPeriodSales', async (params, { dispatch }) => {
    const response = await axios.get('/Dashboard/GetSalesStats', { params })
    return {
        params,
        data: response.data,
    }
})


export const getAllSales = createAsyncThunk('FavStore/getAllSales', async (params, { dispatch }) => {
    const response = await axios.get('/Dashboard/GetSalesStats', { params })
    return {
        params,
        data: response.data,
    }
})

export const getCustomerStats = createAsyncThunk('FavStore/getCustomerStats', async (params, { dispatch }) => {
    const response = await axios.get('/Dashboard/GetCustomerWiseReceivalbeStats', { params })
    return {
        params,
        data: response.data,
    }
})


export const getVendorPayables = createAsyncThunk('FavStore/getVendorPayables', async (params, { dispatch }) => {
    const response = await axios.get('/Dashboard/GetVendorWisePayalbeStats', { params })
    return {
        params,
        data: response.data,
    }
})

export const getTopExpenses = createAsyncThunk('FavStore/getTopExpenses', async (params, { dispatch }) => {
    const response = await axios.get('/Dashboard/GetExpensesStats', { params })
    return {
        params,
        data: response.data,
    }
})

export const getTotalPaymentStats = createAsyncThunk('FavStore/getTotalPaymentStats', async (params, { dispatch }) => {
    const response = await axios.get('/Dashboard/GetGroupTotalPaymentsStats', { params })
    return {
        params,
        data: response.data,
    }
})

export const getTotalPaymentStatistics = createAsyncThunk('FavStore/getTotalPaymentStatistics', async (params, { dispatch }) => {
    const response = await axios.get('/Dashboard/GetTotalPaymentsStats', { params })
    return {
        params,
        data: response.data,
    }
})

export const getTotalPrevPaymentStatistics = createAsyncThunk('FavStore/getTotalPrevPaymentStatistics', async (params, { dispatch }) => {
    const response = await axios.get('/Dashboard/GetTotalPaymentsStats', { params })
    return {
        params,
        data: response.data,
    }
})

export const getTotalExpenseStatistics = createAsyncThunk('FavStore/getTotalExpenseStatistics', async (params, { dispatch }) => {
    const response = await axios.get('/Dashboard/GetTotalExpensesStats', { params })
    return {
        params,
        data: response.data,
    }
})

export const getTotalPrevExpenseStatistics = createAsyncThunk('FavStore/getTotalPrevExpenseStatistics', async (params, { dispatch }) => {
    const response = await axios.get('/Dashboard/GetTotalExpensesStats', { params })
    return {
        params,
        data: response.data,
    }
})


export const getEstimateStats = createAsyncThunk('FavStore/getEstimateStats', async (params, { dispatch }) => {
    const response = await axios.get('/Dashboard/GetSalesEstimateStats', { params })
    return {
        params,
        data: response.data,
    }
})


export const getActivityLogs = createAsyncThunk('FavStore/getActivityLogs', async (params, { dispatch }) => {
    dispatch(setLoading({ getActivityLogs: true }))
    const response = await axios.get('/UserProfile/GetUserLog', { params })
    dispatch(setLoading({ getActivityLogs: false }))
    return {
        params,
        data: response.data,
    }
})

export const FavStoreSlice = createSlice({
    name: 'FavStore',
    initialState: {
        data: [],
        total_sales: [],
        estimate_stats: {data: []},
        customer_stats: [],
        period_sales: [],
        revenue_data: [],
        all_sales: [],
        recoveries_stats: {},
        logs_data: [],
        profit_loss: {},
        purchases_stats: [],
        expenses_stats: [],
        top_expenses: [],
        vendor_stats: [],
        payment_purchase_stats: {},
        total_payment_stats: {},
        total_payment_statistics: {},
        total_expense_statistics: {},
        totalRecords: 1,
        total_logs: 1,
        params: {},
        recovery_params: {},
        all_d_sales_params: {},
        profit_loss_params: {},
        purchases_params: {},
        expenses_params: {},
        payment_purchase_params: {},
        total_payment_params: {},
        total_payment_stats_params: {},
        total_expense_stats_params: {},
        top_expenses_params: {},
        vendor_params: {},
        all_sales_params: {},
        revenue_params: {},
        total_sales_params: {},
        logs_params: {},
        loadingState: { getData: false, getActivityLogs: false}
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                // console.log(state, action)
                state.data = action.payload.data.data.list
                state.totalRecords = action.payload.data.totalRecords
                state.params = action.payload.params
            })
            .addCase(getRevenueStats.fulfilled, (state, action) => {
                state.revenue_data = action.payload.data.data
                state.revenue_params = action.payload.params
            })
            .addCase(getTotalSales.fulfilled, (state, action) => {
                state.total_sales = action.payload.data.data
                state.total_sales_params = action.payload.params
            })
            .addCase(getCustomerStats.fulfilled, (state, action) => {
                state.customer_stats = action.payload.data.data
                state.total_sales_params = action.payload.params
            })
            .addCase(getTotalRecoveriesStats.fulfilled, (state, action) => {
                state.recoveries_stats = action.payload.data.data
                state.recovery_params = action.payload.params
            })
            .addCase(getPeriodSales.fulfilled, (state, action) => {
                state.period_sales = action.payload.data.data
                state.periodRecords = action.payload.data.totalRecords
                state.period_params = action.payload.params
            })
            .addCase(getProfitAndLoss.fulfilled, (state, action) => {
                state.profit_loss = action.payload.data.data
                state.profit_loss_params = action.payload.params
            })
            .addCase(getEstimateStats.fulfilled, (state, action) => {
                state.estimate_stats = action.payload.data.data
                state.estimate_params = action.payload.params
            })
            .addCase(getMonthlyPurchases.fulfilled, (state, action) => {
                state.purchases_stats = action.payload.data.data
                state.purchases_params = action.payload.params
            })
            .addCase(getPrevMonthlyPurchases.fulfilled, (state, action) => {
                state.purchases_stats_prev = action.payload.data.data
                state.purchases_params_prev = action.payload.params
            })
            .addCase(getMonthlyExpenses.fulfilled, (state, action) => {
                state.expenses_stats = action.payload.data.data
                state.expenses_params = action.payload.params
            })
            .addCase(getVendorPayables.fulfilled, (state, action) => {
                state.vendor_stats = action.payload.data.data
                state.vendor_params = action.payload.params
            })
            .addCase(getTopExpenses.fulfilled, (state, action) => {
                state.top_expenses = action.payload.data.data
                state.top_expenses_params = action.payload.params
            })
            .addCase(getPaymentPurchaseStats.fulfilled, (state, action) => {
                state.payment_purchase_stats = (action.payload.data?.data??[]).length > 0 ? action.payload.data?.data[0] : {}
                state.payment_purchase_params = action.payload.params
            })
            .addCase(getTotalPaymentStats.fulfilled, (state, action) => {
                state.total_payment_stats = action.payload.data.data
                state.total_payment_params = action.payload.params
            })
            .addCase(getTotalPaymentStatistics.fulfilled, (state, action) => {
                state.total_payment_statistics = action.payload.data.data
                state.total_payment_stats_params = action.payload.params
            })
            .addCase(getTotalPrevPaymentStatistics.fulfilled, (state, action) => {
                state.total_payment_statistics_prev = action.payload.data.data
                state.total_payment_stats_params_prev = action.payload.params
            })
            .addCase(getTotalExpenseStatistics.fulfilled, (state, action) => {
                state.total_expense_statistics = action.payload.data.data
                state.total_expense_stats_params = action.payload.params
            })
            .addCase(getTotalPrevExpenseStatistics.fulfilled, (state, action) => {
                state.total_expense_statistics_prev = action.payload.data.data
                state.total_expense_stats_params_prev = action.payload.params
            })
            .addCase(getAllSales.fulfilled, (state, action) => {
                state.all_sales = action.payload.data.data
                state.all_d_sales_params = action.payload.params
            })
            .addCase(getActivityLogs.fulfilled, (state, action) => {
                state.logs_data = action.payload.data.data.list
                state.total_logs = action.payload.data.totalRecords
                state.logs_params = action.payload.params
            })
            .addCase(setLoading.fulfilled, (state, action) => {
                state.loadingState = action.payload
            })
    }
})

export default FavStoreSlice.reducer
