// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'

export const setLoading = createAsyncThunk('RILCustomization/setLoading', async (loadingState) => ({ ...loadingState }))

export const getData = createAsyncThunk('RILCustomization/getData', async (params, { dispatch }) => {
    dispatch(setLoading({ getData: true }))
    const response = await axios.get('/InvoicePreviewCustomization/GetRILCustomization', { params })
    dispatch(setLoading({ getData: false }))
    return {
        params,
        data: response.data,
    }
})

export const getBank = createAsyncThunk('RILCustomization/getBank', async (params, { dispatch }) => {
    dispatch(setLoading({ getData: true }))
    const response = await axios.get('/Locations/GetBankDetails', { params })
    dispatch(setLoading({ getData: false }))
    return {
        params,
        data: response.data,
    }
})

export const rilCustomizationSlice = createSlice({
    name: 'rilCustomization',
    initialState: {
        data: {},
        bank_data: {},
        params: {},
        loadingState: { getData: false }
        // allData: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data.data
                state.params = action.payload.params
            })
            .addCase(getBank.fulfilled, (state, action) => {
                state.bank_data = action.payload.data.data
                state.params = action.payload.params
            })
            .addCase(setLoading.fulfilled, (state, action) => {
                state.loadingState = action.payload
            })
    }
})

export default rilCustomizationSlice.reducer
