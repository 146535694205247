// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'

export const setLoading = createAsyncThunk('appBudget/setLoading', async (loadingState) => ({ ...loadingState }))

export const getData = createAsyncThunk('appBudget/getData', async (params, { dispatch, getState }) => {
  dispatch(setLoading({ getData: true }))
  try {
      const responseConsolidated = await axios.get('/Budgets/List', {params})
      dispatch(setLoading({getData: false}))
      return {
        params,
        data: responseConsolidated.data,
      }
    } catch (e) {
      dispatch(setLoading({getData: false}))
      return {
        params,
        data: {data: {list: [], totalRecords: 0}}
      }
    }
})


export const appBudgetSlice = createSlice({
  name: 'appBudget',
  initialState: {
    vendorInvoice: {},
    data: [],
    totalRecords: 1,
    params: {},
    loadingState: { getData: false, getTypeData: false, getJournal: false }
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data.data.list
        state.totalRecords = action.payload.data.totalRecords
        state.params = action.payload.params
      })
      .addCase(setLoading.fulfilled, (state, action) => {
        state.loadingState = action.payload
      })
  }
})

export default appBudgetSlice.reducer
