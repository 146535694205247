// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'

export const setLoading = createAsyncThunk('AttachmentFileManager/setLoading', async (loadingState) => ({ ...loadingState }))

export const getData = createAsyncThunk('AttachmentFileManager/getData', async (params, { dispatch }) => {
  dispatch(setLoading({ getData: true }))
  const response = await axios.get('/FileUpload/SelectFoldersTreeMenu', { params })

  dispatch(setLoading({ getData: false }))

  return {
    params,
    data: response.data,
  }
})

export const getStorage = createAsyncThunk('AttachmentFileManager/getStorage', async (params, { dispatch }) => {
  dispatch(setLoading({ getData: true }))

  const response = await axios.get('/FileFoldersUpload/GetOrgUsedSpaceStats', { params })

  dispatch(setLoading({ getData: false }))

  return {
    params,
    data: response.data,
  }
})

export const getFolderData = createAsyncThunk('AttachmentFileManager/getFolderData', async (params, { dispatch }) => {
  dispatch(setLoading({ getFolderData: true }))

    let response;

    if(`${params?.folderId}`=== '0') {
        response = await axios.get('/FileUpload/SelectMainFoldersList', {params})
    } else {
      let tempParent = params?.parentFolderId;
        params.parentFolderId  = params?.parentFolderId === 0 ? params?.folderId : params?.parentFolderId;
        params.folderId  = tempParent === 0 ? undefined : params?.folderId;
        response = await axios.get(`/FileUpload/GetFolderData/${params?.parentFolderId??params?.folderId}`, {params})
    }

  dispatch(setLoading({ getFolderData: false }))

  return {
    params,
    data: response.data,
  }
})

export const AttachmentFileManagerSlice = createSlice({
  name: 'AttachmentFileManager',
  initialState: {
    data: [],
    folder_data: [],
    files_data: [],
    storage_data: {},
    totalRecords: 1,
    params: {},
    storage_params: {},
    folder_params: {},
    loadingState: { getData: false, getFolderData: false, getStorage: false }
    // allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
        .addCase(getData.fulfilled, (state, action) => {
          state.data = action.payload.data.data.list
          state.totalRecords = action.payload.data.totalRecords
          state.params = action.payload.params
        })
        .addCase(getStorage.fulfilled, (state, action) => {
          state.storage_data = action.payload.data.data
          state.storage_params = action.payload.params
        })
        .addCase(getFolderData.fulfilled, (state, action) => {
          if(`${action?.payload.params?.folderId}`=== '0') {
            state.folder_data = action.payload.data.data.list
            state.files_data = []
          } else {
            state.folder_data = action.payload.data?.data?.folders??[]
            state.files_data = action.payload.data.data.files
          }
          state.folder_params = action.payload.params
        })
        .addCase(setLoading.fulfilled, (state, action) => {
          state.loadingState = action.payload
        })
  }
})

export default AttachmentFileManagerSlice.reducer
