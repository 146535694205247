// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'

export const setLoading = createAsyncThunk('appVendor/setLoading', async (loadingState) => ({ ...loadingState }))

export const getData = createAsyncThunk('appVendor/getData', async (params, { dispatch }) => {
  dispatch(setLoading({ getData: true }))
  const response = await axios.get('/Vendors/List', { params })
  dispatch(setLoading({ getData: false }))
  return {
    params,
    data: response.data
  }
})

export const setShowDetail = createAsyncThunk('appVendor/setShowDetail', async (showDetail) => ({ ...showDetail }))


export const setSelectedVendor = createAsyncThunk('appVendor/setSelectedVendor', async (paramData, {dispatch}) => {

  dispatch(setShowDetail({ showDetail: paramData.showDetail }))
  if(paramData.selectedVendor) {
    dispatch(setLoading({ loadDetail: true }))
    const res = await axios.get(`/Vendors/GetDetails/${paramData.selectedVendor.recno}`)
    dispatch(setLoading({ loadDetail: false }))
    return {
      selectedVendor: res.data.data
    }
  }else {
    return {
      selectedVendor: paramData.selectedVendor
    }
  }
})


export const getTypeData = createAsyncThunk('appVendor/getTypeData', async (params, { dispatch }) => {
  dispatch(setLoading({ getTypeData: true }))
  const response = await axios.get('/Vendors/List', { params })
  dispatch(setLoading({ getTypeData: false }))
  return {
    params,
    data: response.data
  }
})

export const getJournal = createAsyncThunk('appVendor/getJournal', async (recno, { dispatch }) => {
  dispatch(setLoading({ getJournal: true }))
  const response = await axios.get(`/Vendors/${recno}`)
  dispatch(setLoading({ getJournal: false }))

  return response.data
})


export const deleteInvoice = createAsyncThunk('appVendor/deleteInvoice', async (id, { dispatch, getState }) => {
  dispatch(setLoading({ deleteInvoice: true }))
  await axios.delete('/apps/invoice/delete', { id })
  await dispatch(getData(getState().invoice.params))
  dispatch(setLoading({ deleteInvoice: false }))
  return id
})

export const appVendorSlice = createSlice({
  name: 'appVendor',
  initialState: {
    employees: {},
    data: [],
    totalRecords: 1,
    typeData: [],
    totalTypeRecords: 1,
    params: {},
    typeParams: {},
    selectedVendor: {},
    showDetail: false,
    loadingState: { getData: false, getTypeData: false, getJournal: false }
    // allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data.data.list
        state.totalRecords = action.payload.data.totalRecords
        state.params = action.payload.params
      })
      .addCase(getTypeData.fulfilled, (state, action) => {
        state.typeData = action.payload.data.data.list
        state.totalTypeRecords = action.payload.data.totalRecords
        state.typeParams = action.payload.params
      })
      .addCase(getJournal.fulfilled, (state, action) => {
        state.employee = action.payload
      })
      .addCase(setLoading.fulfilled, (state, action) => {
        state.loadingState = action.payload
      })
        .addCase(setSelectedVendor.fulfilled, (state, action) => {
          state.selectedVendor = action.payload.selectedVendor
        })
        .addCase(setShowDetail.fulfilled, (state, action) => {
          state.showDetail = action.payload.showDetail
        })
  }
})

export default appVendorSlice.reducer
