// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'

export const setLoading = createAsyncThunk('appJournal/setLoading', async (loadingState) => ({ ...loadingState }))

export const getData = createAsyncThunk('appJournal/getData', async (params, { dispatch }) => {
  dispatch(setLoading({ getData: true }))
  const response = await axios.get('/JV/List', { params })
  const consolidatedResponse = await axios.get('/JV/ConsolidatedList', { params })

  dispatch(setLoading({ getData: false }))
  return {
    params,
    data: response.data,
    consolidated: consolidatedResponse.data,
  }
})

export const getJvActivityLogData = createAsyncThunk('appJournal/getJvActivityLogData', async (recno, { dispatch }) => {
  dispatch(setLoading({ getJvActivityLogData: true }))
  const response = await axios.get(`/JV/GetJVLog/${recno}`)
  dispatch(setLoading({ getJvActivityLogData: false }))
  return {
    data: response.data
  }
})

export const getTypeData = createAsyncThunk('appJournal/getTypeData', async (params, { dispatch }) => {
  dispatch(setLoading({ getTypeData: true }))
  const response = await axios.get('/JVTypes/List', { params })
  dispatch(setLoading({ getTypeData: false }))
  return {
    params,
    data: response.data
  }
})

export const getJournal = createAsyncThunk('appJournal/getJournal', async (recno, { dispatch }) => {
  dispatch(setLoading({ getJournal: true }))
  const response = await axios.get(`/JV/${recno}`)
  dispatch(setLoading({ getJournal: false }))

  return response.data
})


export const deleteInvoice = createAsyncThunk('appJournal/deleteInvoice', async (id, { dispatch, getState }) => {
  dispatch(setLoading({ deleteInvoice: true }))
  await axios.delete('/apps/invoice/delete', { id })
  await dispatch(getData(getState().invoice.params))
  dispatch(setLoading({ deleteInvoice: false }))
  return id
})

export const appJournalSlice = createSlice({
  name: 'appJournal',
  initialState: {
    journal: {},
    data: [],
    consolidated: [],
    totalRecords: 1,
    totalRecordsConsolidated: 1,
    activityLogs: [],
    totalLogsRecords: 1,
    typeData: [],
    totalTypeRecords: 1,
    params: {},
    typeParams: {},
    loadingState: { getData: false, getTypeData: false, getJournal: false }
    // allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data.data.list
        state.consolidated = action.payload.consolidated.data.list
        state.totalRecords = action.payload.data.totalRecords
        state.totalRecordsConsolidated = action.payload.consolidated.totalRecords
        state.params = action.payload.params
      })
      .addCase(getJvActivityLogData.fulfilled, (state, action) => {
        state.activityLogs = action.payload.data.data.list
        state.totalLogsRecords = action.payload.data.totalRecords
      })
      .addCase(getTypeData.fulfilled, (state, action) => {
        state.typeData = action.payload.data.data.list
        state.totalTypeRecords = action.payload.data.totalRecords
        state.typeParams = action.payload.params
      })
      .addCase(getJournal.fulfilled, (state, action) => {
        state.journal = action.payload
      })
      .addCase(setLoading.fulfilled, (state, action) => {
        state.loadingState = action.payload
      })
  }
})

export default appJournalSlice.reducer
