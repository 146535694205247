// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// ** Axios Imports
import axios from "axios";

export const setLoading = createAsyncThunk("taxesSettings/setLoading", async (loadingState) => ({ ...loadingState }));

export const getData = createAsyncThunk("taxesSettings/getData", async (params, { dispatch }) => {
   dispatch(setLoading({ getData: true }));
   const response = await axios.get("/Organizations/GetTaxDetails", { params });

   dispatch(setLoading({ getData: false }));
   return {
      params,
      data: response.data,
   };
});

export const getCorporateData = createAsyncThunk("taxesSettings/getCorporateData", async (params, { dispatch }) => {
   dispatch(setLoading({ getCorporateData: true }));
   const response = await axios.get("/Organizations/GetCorporateTaxDetails", { params });

   dispatch(setLoading({ getCorporateData: false }));
   return {
      params,
      data: response.data,
   };
});

export const taxesSettings = createSlice({
   name: "taxesSettings",
   initialState: {
      data: {},
      corporate_data: {},
      params: {},
      loadingState: { getData: false, getCodeCustomization: false, getCorporateData: false },
      // allData: []
   },
   reducers: {},
   extraReducers: (builder) => {
      builder
         .addCase(getData.fulfilled, (state, action) => {
            state.data = action.payload.data.data;
            state.params = action.payload.params;
         })
          .addCase(getCorporateData.fulfilled, (state, action) => {
            state.corporate_data = action.payload.data.data;
            state.params = action.payload.params;
         })
         .addCase(setLoading.fulfilled, (state, action) => {
            state.loadingState = action.payload;
         });
   },
});

export default taxesSettings.reducer;
