// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import {getTotalTrialBalance} from "../../../../utility/Utils";

export const setLoading = createAsyncThunk('PaymentReceived/setLoading', async (loadingState) => ({ ...loadingState }))

export const getData = createAsyncThunk('PaymentReceived/getData', async (params, { dispatch }) => {
    dispatch(setLoading({ getData: true }))
    try {
        let response;
        if (params.Location) {
            response = await axios.get('/AccountsReports/CustomerPaymentsReceived', {params})
            console.log(response,"response Data")
        } else {
            response = await axios.get('/AccountsReports/CustomerPaymentsReceived', {params})
        }
        return {
            params,
            data: response.data,
        }
    }catch (e){
        console.log(e);
        return {
            params,
            data: {data: {
                list: []
            }}
        }
    }finally {
        dispatch(setLoading({ getData: false }))
    }

})



export const paymentReceived = createSlice({
    name: 'PaymentReceived',
    initialState: {
        data: [],
        totalRecords: 1,
        params: {},
        loadingState: { getData: false }
        // allData: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data.data.list;
                state.totalRecords = action.payload.data.totalRecords;
                state.params = action.payload.params;
            })
            .addCase(setLoading.fulfilled, (state, action) => {
                state.loadingState = action.payload;
            });
    },
})

export default paymentReceived.reducer
