// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'

export const setLoading = createAsyncThunk('appVatReturns/setLoading', async (loadingState) => ({ ...loadingState }))

export const getData = createAsyncThunk('appVatReturns/getData', async (params, { dispatch, getState }) => {
  dispatch(setLoading({ getData: true }))
  const response = await axios.get('/VatReturns/List', {params})
  dispatch(setLoading({getData: false}))
  return {
    params,
    data: response.data
  }
})

export const getDetailData = createAsyncThunk('appVatReturns/getDetailData', async (params, { dispatch, getState }) => {
  dispatch(setLoading({ getDetailData: true }))
  const response = await axios.get(`/VatReturns/GetDetail/${params.Recno}`, {params})
  dispatch(setLoading({getDetailData: false}))
  return {
    params,
    data: response.data
  }
})

export const getSerialData = createAsyncThunk('appVatReturns/getSerialData', async (params, { dispatch, getState }) => {
  dispatch(setLoading({ getSerialData: true }))
  const response = await axios.get(`/VatReturns/SelectFileVatReturnReportDetails`, {params})
  dispatch(setLoading({getSerialData: false}))
  return {
    params,
    data: response.data
  }
})

export const getAdjustmentData = createAsyncThunk('appVatReturns/getAdjustmentData', async (params, { dispatch, getState }) => {
  dispatch(setLoading({ getAdjustmentData: true }))
  const response = await axios.get(`/VatReturns/SelectFileVatReturnReportAdjustmentDetails`, {params})
  dispatch(setLoading({getAdjustmentData: false}))
  return {
    params,
    data: response.data
  }
})

export const appVatReturnsSlice = createSlice({
  name: 'appVatReturns',
  initialState: {
    data: [],
    detail_data: {},
    serial_data: [],
    serial_header: {},
    totalSerialRecords: 1,
    totalRecords: 1,
    params: {},
    loadingState: { getData: false }
    // allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data.data.list
        state.totalRecords = action.payload.data.data.totalRecords
        state.params = action.payload.params
      })
      .addCase(getSerialData.fulfilled, (state, action) => {
        state.serial_data = action.payload.data.data.list
        state.serial_header = action.payload.data.data
        state.totalSerialRecords = action.payload.data.data.totalRecords
        state.params = action.payload.params
      })
      .addCase(getAdjustmentData.fulfilled, (state, action) => {
        state.adjustment_data = action.payload.data.data.list
        state.adjustment_header = action.payload.data.data
        state.totalAdjustmentRecords = action.payload.data.data.totalRecords
        state.adjustment_params = action.payload.params
      })
      .addCase(getDetailData.fulfilled, (state, action) => {
        state.detail_data = action.payload.data.data
        state.detail_params = action.payload.params
      })
      .addCase(setLoading.fulfilled, (state, action) => {
        state.loadingState = action.payload
      })
  }
})

export default appVatReturnsSlice.reducer
