// ** React Imports
import * as service from '@api/api.service'
import {createContext, useEffect, useMemo, useState} from 'react'
import {useSelector} from 'react-redux'
import {getFingerprint, imageUrlToBase64} from '../Utils'
import {useHistory} from "react-router-dom";

// ** Create Context
const AppDefaultsContext = createContext()

const AppDefaultsProvider = ({children}) => {
    // ** Hooks
    const store = useSelector(state => state.auth)
    const userData = store.userData

    // ** State
    const [defaultCurrency, setDefaultCurrency] = useState({code: "AED"})
    const [currencies, setCurrencies] = useState([])
    const [unauthorized, setUnauthorized] = useState(false)
    const [defaultCostLocation, setDefaultCostLocation] = useState()
    const [defaultTanent, setDefaultTanent] = useState()
    const [systemInfo, setSystemInfo] = useState({ip: undefined, long: undefined, lat: undefined, name: undefined})

    const loadDefaultCostLocation = async () => {
        try {
            const response = await service.Get('/Defaults/Locations', {Recno: userData.loginLocationId});
            setDefaultCostLocation(response.data.length ? response.data[0] : undefined)
            setUnauthorized(false)
        } catch (e) {
            if (typeof e === "string") {
                if (e === "You are Not autherized") {
                    setUnauthorized(true)
                }
            } else {
                setUnauthorized(false)
            }
        }
    }

    const loadDefaultTanent = async () => {
        try {
            const response = await service.Get('/Defaults/TenantDetails', {Recno: userData.loginTenantId});

            if(userData?.tenantDetails.length) {
                let tanent = userData?.tenantDetails.find((ele) => ele.tenantId === userData.loginTenantId);
                if(tanent?.logo) {
                    imageUrlToBase64(`${process.env.REACT_APP_API_URL_production}${tanent.logo}`)
                        .then(base64 => {
                            setDefaultTanent(response.data.length ? {...response.data[0], base64Logo: base64} : undefined)
                            setUnauthorized(false)
                            console.log(`BASE64 LOGO ${base64}`); // Logs the base64 string
                        })
                        .catch(error => {
                            console.error(error);
                            setDefaultTanent(response.data.length ? response.data[0] : undefined)
                            setUnauthorized(false)
                        });
                } else {
                    setDefaultTanent(response.data.length ? response.data[0] : undefined)
                    setUnauthorized(false)
                }
            } else {
                setDefaultTanent(response.data.length ? response.data[0] : undefined)
                setUnauthorized(false)
            }
        } catch (e) {
            if (typeof e === "string") {
                if (e === "You are Not autherized") {
                    setUnauthorized(true)
                }
            } else {
                setUnauthorized(false)
            }
        }
    }

    const setDefaultResetAuthorized = () => {
        setUnauthorized(false)
    }

    const loadTenantCurrencies = async () => {
        const response = await service.Get(`/Defaults/TenantCurrencies`)
        const defCurrency = response.data.find(e => e.isDefaultCurrency)
        setDefaultCurrency(defCurrency??{code: "AED"})
        setCurrencies(response.data)
        return response.data
    }

    const getIp = async () => {
        try {
            const ipApiResponse = await fetch(`https://api.ipify.org/?format=json`).then(body => body.json())
            setSystemInfo(info => ({...info, ...ipApiResponse}))
        } catch (_ignored) {

        }
    }

    const getGeoLocation = async () => {
        function showError() {
            // console.log('GeoLocation Error', { error })
        }

        function showPosition(position) {
            setSystemInfo(info => ({...info, long: `${position.coords.longitude}`, lat: `${position.coords.latitude}`}))
        }

        navigator.geolocation?.getCurrentPosition(showPosition, showError)
    }

    const getSystemFingerprint = async () => {
        const fingerPrint = localStorage.getItem('fingerprint')
        if (!!fingerPrint) {
            setSystemInfo(info => ({...info, name: JSON.parse(fingerPrint)}))
        } else {
            getFingerprint(name => {
                localStorage.setItem('fingerprint', JSON.stringify(name))
                setSystemInfo(info => ({...info, name}))
            })
        }
    }

    const updateTanantLogo = (updated) => {
        setDefaultTanent(({...defaultTanent, ...updated}))
    }


    const values = useMemo(() => ({
            currencies,
            unauthorized,
            defaultCurrency,
            defaultCostLocation,
            defaultTanent,
            updateTanantLogo,
            systemInfo,
            userData,
            setDefaultResetAuthorized,
            loadDefaultTanent,
            loadTenantCurrencies
        }),
        [
            currencies,
            unauthorized,
            defaultCurrency,
            defaultCostLocation,
            defaultTanent,
            systemInfo,
            updateTanantLogo,
            setDefaultResetAuthorized,
            loadDefaultTanent,
            userData
        ]
    )


    //** ComponentDidMount
    useEffect(() => {
        if (userData && Object.keys(userData).length > 0) {
            Promise.all([
                loadDefaultCostLocation(),
                loadDefaultTanent(),
                loadTenantCurrencies()
            ])
        }
    }, [userData])

    useEffect(() => {
        Promise.all([
            getIp(),
            getGeoLocation(),
            getSystemFingerprint()
        ])
    }, [])

    return <AppDefaultsContext.Provider value={values}>{children}</AppDefaultsContext.Provider>
}

export {AppDefaultsContext, AppDefaultsProvider}

