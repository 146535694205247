// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'

export const setLoading = createAsyncThunk('coaAccounts/setLoading', async (loadingState) => ({ ...loadingState }))

export const getAccountTypes = createAsyncThunk('coaAccounts/getAccountTypes', async () => {
  const response = await axios.get('/Defaults/COATypes')
  return response.data
})

export const getAccountDetailTypes = createAsyncThunk('coaAccounts/getAccountDetailTypes', async (params) => {
  const response = await axios.get(`/Defaults/COALevel2TypeWiseControlCOAList?Type=${params?.type}`)
  return response.data
})

export const getAccountSubDetailTypes = createAsyncThunk('coaAccounts/getAccountSubDetailTypes', async (params) => {
  const response = await axios.get(`/Defaults/DetailTypeWiseCOAList?Type=${params?.type}`)
  return response.data
})

export const getAccounts = createAsyncThunk('coaAccounts/getAccounts', async (params,{ dispatch }) => {
  dispatch(setLoading({ getData: true }))

  if(!params){
    params = {}
  }
  const response = await axios.get('/COA/ListV2', {params})
  dispatch(setLoading({getData: false}))
  return response.data
})

export const getAccountsCategories = createAsyncThunk('coaAccounts/getAccountCategorirs', async () => {
  const response = await axios.get('/Defaults/COACategories')
  return response.data
})

export const getAccessableLocations = createAsyncThunk('coaAccounts/getAccessableLocations', async () => {
  const response = await axios.get('/Defaults/COAAccessibleLocations')
  return response.data
})

export const getDefaultLocations = createAsyncThunk('coaAccounts/getDefaultLocations', async () => {
  const response = await axios.get('/Defaults/Locations')
  return response.data
})

// export const selectChat = createAsyncThunk('coaAccounts/selectChat', async (id, { dispatch }) => {
//   const response = await axios.get('/apps/chat/get-chat', { id })
//   await dispatch(getChatContacts())
//   return response.data
// })

// export const sendMsg = createAsyncThunk('coaAccounts/sendMsg', async (obj, { dispatch }) => {
//   const response = await axios.post('/apps/chat/send-msg', { obj })
//   await dispatch(selectChat(obj.contact.id))
//   return response.data
// })

export const coaAccountsSlice = createSlice({
  name: 'coaAccounts',
  initialState: {
    accounts: [],
    totalRecords: 0,
    accountCategories: [],
    accessableLocations: [],
    defaultLocations: [],
    accountTypes: [],
    accountDetailTypes: [],
    accountSubDetailTypes: [],
    loadingState: { getData: false }
  },
  reducers: {},
  extraReducers: builder => {
    builder
      // .addCase(getUserProfile.fulfilled, (state, action) => {
      //   state.userProfile = action.payload
      // })
      .addCase(getAccountTypes.fulfilled, (state, action) => {
        state.accountTypes = action.payload.data
      })
      .addCase(getAccountDetailTypes.fulfilled, (state, action) => {
        state.accountDetailTypes = action.payload.data
      })
      .addCase(getAccountSubDetailTypes.fulfilled, (state, action) => {
        state.accountSubDetailTypes = action.payload.data
      })
      .addCase(getAccounts.fulfilled, (state, action) => {
        let sortedList = action.payload.data.list.sort((a,b) => ((b.coaBalance??0) - (a.coaBalance??0)))
        state.accounts = sortedList
        state.totalRecords = action.payload.totalRecords
      })
      .addCase(getAccountsCategories.fulfilled, (state, action) => {
        state.accountCategories = action.payload.data
      })
      .addCase(getAccessableLocations.fulfilled, (state, action) => {
        state.accessableLocations = action.payload.data
      })
      .addCase(getDefaultLocations.fulfilled, (state, action) => {
        state.defaultLocations = action.payload.data
      }).addCase(setLoading.fulfilled, (state, action) => {
          state.loadingState = action.payload
        })
  }
})

export default coaAccountsSlice.reducer
