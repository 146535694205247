// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// ** Axios Imports
import axios from "axios";
import { getTotalTrialBalance } from "../../../../utility/Utils";

export const setLoading = createAsyncThunk("vendorAdvance/setLoading", async (loadingState) => ({ ...loadingState }));

export const getData = createAsyncThunk("vendorAdvance/getData", async (params, { dispatch }) => {
   dispatch(setLoading({ getData: true }));
   try {
      let response;
      if (params.Location) {
         response = await axios.get("/AccountsReports/VendorAdvancesSummary", { params });
      } else {
         response = await axios.get("/AccountsReports/VendorAdvancesSummary", { params });
      }
      return {
         params,
         data: response.data,
      };
   } catch (e) {
      console.log(e);
      return {
         params,
         data: {
            data: {
               list: [],
            },
         },
      };
   } finally {
      dispatch(setLoading({ getData: false }));
   }
});

export const vendorAdvance = createSlice({
   name: "vendorAdvance",
   initialState: {
      data: [],
      totalRecords: 1,
      params: {},
      loadingState: { getData: false },
      // allData: []
   },
   reducers: {},
   extraReducers: (builder) => {
      builder
         .addCase(getData.fulfilled, (state, action) => {
            let data = action.payload.data.data.list;
            let dataList = [];
            for (let i = 0; i < data.length; i++) {
               let d = { ...data[i] };
               let { total_open_dr, total_open_cr, total_dr, total_cr, total_close_dr, total_close_cr } = (d.childs || []).length > 0 ? getTotalTrialBalance(d) : { total_open_dr: 0, total_open_cr: 0, total_dr: 0, total_cr: 0, total_close_dr: 0, total_close_cr: 0 };
               let closeBalance = d.obdr + d.dr - (d.obcr + d.cr);
               if (closeBalance >= 0) {
                  d.cbdr = closeBalance;
                  d.cbcr = 0;
               } else {
                  d.cbcr = -1 * closeBalance;
                  d.cbdr = 0;
               }
               // alert(total_open_dr)
               // alert(d.obdr)
               d.total_open_dr = total_open_dr + d.obdr;
               d.total_open_cr = total_open_cr + d.obcr;
               d.total_dr = total_dr + d.dr /*> 0 ? d.dr : (-1 * d.dr)*/;
               d.total_cr = total_cr + d.cr /*> 0 ? d.cr : (-1 * d.cr)*/;

               let totalCloseBalance = d.total_open_dr + d.total_dr - (d.total_open_cr + d.total_cr);

               if (totalCloseBalance >= 0) {
                  d.total_close_dr = totalCloseBalance;
                  d.total_close_cr = 0;
               } else {
                  d.total_close_cr = -1 * totalCloseBalance;
                  d.total_close_dr = 0;
               }

               dataList.push(d);
            }
            console.log(`TOTAL VALUE DR CR ${JSON.stringify(dataList)}`);
            state.data = dataList;
            state.totalRecords = action.payload.data.data.totalRecords;
            state.params = action.payload.params;
         })
         .addCase(setLoading.fulfilled, (state, action) => {
            state.loadingState = action.payload;
         });
   },
});

export default vendorAdvance.reducer;
