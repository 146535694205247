import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'


export const setLoading = createAsyncThunk('BillableDetail/setLoading', async (loadingState) => ({ ...loadingState }))

export const getData = createAsyncThunk('BillableDetail/getData', async (params, { dispatch }) => {
    dispatch(setLoading({ getData: true }))
    try {
        let response = await axios.get('/AccountsReports/GetBillableDetails', {params})

        return {
            params,
            data: response.data,
        }
    }catch (e){
        console.log(e);
        return {
            params,
            data: {data: {
                list: []
            }}
        }
    }finally {
        dispatch(setLoading({ getData: false }))
    }

})



export const billableDetail = createSlice({
    name: 'BillableDetail',
    initialState: {
        data: [],
        totalRecords: 1,
        params: {},
        loadingState: { getData: false }
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data.data.list;
                state.totalRecords = action.payload.data.totalRecords;
                state.params = action.payload.params;
            })
            .addCase(setLoading.fulfilled, (state, action) => {
                state.loadingState = action.payload;
            });
    },
})

export default billableDetail.reducer
