// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('accountDetailSelector/getData', async params => {

    const response = await axios.get('/Defaults/DetailCOAList', { params })
    return {
        params: response.data.params,
        data: response.data
    }
})

export const getDefaultData = createAsyncThunk('accountDetailSelector/getDefaultData', async params => {
    const response = await axios.get('/Defaults/DetailCOAList', { params })

    return {
        params: response.data.params,
        data: response.data.data[0]
    }
})

export const accountDetailSlice= createSlice({
    name: 'accountDetailSelector',
    initialState: {
        data: [],
        total: 1,
        params: {},
        selectedBank: {},
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getData.fulfilled, (state, action) => {
            state.data = action.payload.data.data
            state.params = action.payload.params
        }).addCase(getDefaultData.fulfilled, (state, action) => {
            state.selectedBank = action.payload.data
            state.params = action.payload.params
        })
    }
})

export const { selectPermission } = accountDetailSlice.actions

export default accountDetailSlice.reducer
