import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const setLoading = createAsyncThunk("ledgerReport/setLoading", async (loadingState) => ({ ...loadingState }));


export const getData = createAsyncThunk("ledgerReport/getData", async (params, { dispatch }) => {
    dispatch(setLoading({ getData: true }));

    try {
        let response;
        if(params.Customer){
            response = await axios.get(`/AccountsReports/JournalLedgerCOACustomerLocationList`, {params});
        } else if(params.Vendor){
            response = await axios.get(`/AccountsReports/JournalLedgerCOAVendorLocationList`, {params});
        }else {
            response = await axios.get(`/AccountsReports/JournalLedgerCOALocationList`, {params});
        }
        return {
            params,
            data: response.data,
        };
    } catch (e) {
        return {
            params,
            data: {
                data: {
                    list: [],
                },
            },
        };
    } finally {
        dispatch(setLoading({ getData: false }));
    }
});

export const ledgerReport = createSlice({
    name: "ledgerReport",
    initialState: {
        data: [],
        totalRecords: 1,
        params: {},
        loadingState: { getData: false },
        // allData: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data.data.list;
                state.totalRecords = action.payload.data.totalRecords;
                state.params = action.payload.params;
            })
            .addCase(setLoading.fulfilled, (state, action) => {
                state.loadingState = action.payload;
            });
    },
});

export default ledgerReport.reducer;
