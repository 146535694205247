// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import {collectChilds, getTotal, getTotalCompare} from "../../../../utility/Utils";

export const setLoading = createAsyncThunk('ProjectProfitReport/setLoading', async (loadingState) => ({...loadingState}))

export const getData = createAsyncThunk('ProjectProfitReport/getData', async (params, {dispatch}) => {
    dispatch(setLoading({getData: true}))
    try {
        if(params?.projectId) {
            let response = await axios.get(`/Projects/ProfitAndLossReport/${params.projectId}`, {params})

            return {
                params,
                data: response.data,
            }
        } else {
            return {
                params,
                data: {
                    data: {
                        list: []
                    }
                }
            }    
        }
    } catch (e) {
        console.log(e);
        return {
            params,
            data: {
                data: {
                    list: []
                }
            }
        }
    } finally {
        dispatch(setLoading({getData: false}))
    }

})

export const ProjectProfitLossStore = createSlice({
    name: 'ProjectProfitReport',
    initialState: {
        data: [],
        transactionCurrency: {code: "AED", symbol: "AED", name: "United Arab Emirates Direm"},
        totalRecords: 1,
        params: {},
        loadingState: {getData: false}
        // allData: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                let data = action.payload.data.data.list
                let transactionCurrency = {code: "AED", symbol: "AED", name: "United Arab Emirates Direm"};

                let dataList = [];
                if (data) {
                    data.map((e => {
                        let a = {...e};

                        transactionCurrency = {
                            code: e.transactionCurrencyCode??"AED",
                            symbol: e.transactionCurrencySymbol??"AED",
                            name: e.transactionCurrencyName??"United Arab Emirates Direm"
                        };

                        if (a.accounts) {
                            let totalAmount = 0;
                            a.accounts.map((r => {
                                totalAmount += r.amount;
                                if ((r.childs || []).length > 0) {
                                    r.childAmount = getTotal(r);
                                    totalAmount += r.childAmount;
                                }
                            }))
                            a.amount = totalAmount;
                        }
                        dataList.push(a);
                    }));

                    let incomeList = dataList.filter(e => e.sortLvl === 1);
                    let income = incomeList.length > 0 ? incomeList[0] : null;
                    let costSaleList = dataList.filter(e => e.sortLvl === 2);
                    let costOSale = costSaleList.length > 0 ? costSaleList[0] : null;
                    let expenseList = dataList.filter(e => e.sortLvl === 3);
                    let expense = expenseList.length > 0 ? expenseList[0] : null;
                    let otherExpList = dataList.filter(e => e.sortLvl === 4);
                    let otherExp = otherExpList.length > 0 ? otherExpList[0] : null;
                    let otherIncomeList = dataList.filter(e => e.sortLvl === 5);
                    let otherIncome = otherIncomeList.length > 0 ? otherIncomeList[0] : null;

                    let grossData;
                    if (income && costOSale) {
                        grossData = {
                            headName: "Gross Profit",
                            total: true,
                            amount: costOSale.formula === "-" ? income.amount - costOSale.amount : income.amount - costOSale.amount,
                            accounts: []
                        }
                    } else {
                        grossData = {
                            headName: "Gross Profit",
                            total: true,
                            amount: 0,
                            accounts: []
                        }
                    }

                    dataList.splice(2, 0, grossData)
                    let netProfit = grossData.amount;

                    if (expense) {
                        netProfit -= expense.amount ?? 0;
                    }

                    if (otherExp) {
                        netProfit -= otherExp.amount ?? 0;
                    }

                    if (otherIncome) {
                        netProfit += otherIncome.amount ?? 0;
                    }

                    let netData = {
                        headName: netProfit > -1 ? "Net Profit" : "Net Loss",
                        total: true,
                        net: true,
                        amount: netProfit,
                        accounts: []
                    }

                    dataList.push(netData)

                }
                state.data = dataList
                state.transactionCurrency = transactionCurrency
                state.totalRecords = action.payload.data.data.totalRecords
                state.params = action.payload.params
            })
            .addCase(setLoading.fulfilled, (state, action) => {
                state.loadingState = action.payload
            })
    }
})

export default ProjectProfitLossStore.reducer
