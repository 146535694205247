import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

export const setLoading = createAsyncThunk('expense/setLoading', async (loadingState) => ({ ...loadingState }))

export const getData = createAsyncThunk('expense/getData', async (params, { dispatch }) => {
  dispatch(setLoading({ getData: true }))

  const consolidated_response = await axios.get('/Expenses/ConsolidatedList', { params: { ...params, ServiceInvoice: false } })

  dispatch(setLoading({ getData: false }))
  return {
    params,
    data: {data: {list: [], totalRecords: 0}},
    consolidated: consolidated_response.data
  }
})

export const getExpenseActivityLogData = createAsyncThunk('appPurchase/getExpenseActivityLogData', async (recno, { dispatch }) => {
  dispatch(setLoading({ getExpenseActivityLogData: true }))
  const response = await axios.get(`/Expenses/GetExpenseLog/${recno}`)
  dispatch(setLoading({ getExpenseActivityLogData: false }))
  return {
    data: response.data
  }
})

export const getTypeData = createAsyncThunk('expense/getTypeData', async (params, { dispatch }) => {
  dispatch(setLoading({ getTypeData: true }))
  const response = await axios.get('/Expenses/List/List', { params })
  dispatch(setLoading({ getTypeData: false }))
  return {
    params,
    data: response.data
  }
})

export const getJournal = createAsyncThunk('expense/getJournal', async (recno, { dispatch }) => {
  dispatch(setLoading({ getJournal: true }))
  const response = await axios.get(`/Expenses/${recno}`)
  dispatch(setLoading({ getJournal: false }))

  return response.data
})

export const deleteInvoice = createAsyncThunk('expense/deleteInvoice', async (id, { dispatch, getState }) => {
  dispatch(setLoading({ deleteInvoice: true }))
  await axios.delete('/expenses', { id })
  await dispatch(getData(getState().invoice.params))
  dispatch(setLoading({ deleteInvoice: false }))
  return id
})

export const appPurchaseSlice = createSlice({
  name: 'expense',
  initialState: {
    appPurchase: {},
    data: [],
    totalRecords: 1,
    consolidatedTotalRecords: 1,
    activityLogs: [],
    totalLogsRecords: 1,
    typeData: [],
    totalTypeRecords: 1,
    params: {},
    typeParams: {},
    loadingState: { getData: false, getTypeData: false, getJournal: false }
    // allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {

        state.data = action.payload.data.data.list
        state.consolidated = action.payload.consolidated?.data?.list??[]
        state.totalRecords = action.payload.data.totalRecords
        state.consolidatedTotalRecords = action.payload.consolidated?.totalRecords??0
        state.params = action.payload.params
      })
      .addCase(getExpenseActivityLogData.fulfilled, (state, action) => {
        state.activityLogs = action.payload.data.data.list
        state.totalLogsRecords = action.payload.data.totalRecords
      })
      .addCase(getTypeData.fulfilled, (state, action) => {
        state.typeData = action.payload.data.data.list
        state.totalTypeRecords = action.payload.data.totalRecords
        state.typeParams = action.payload.params
      })
      .addCase(getJournal.fulfilled, (state, action) => {
        state.journal = action.payload
      })
      .addCase(setLoading.fulfilled, (state, action) => {
        state.loadingState = action.payload
      })
  }
})

export default appPurchaseSlice.reducer
