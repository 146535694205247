// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'

export const setLoading = createAsyncThunk('appCustomer/setLoading', async (loadingState) => ({...loadingState}))

export const setShowDetail = createAsyncThunk('appCustomer/setShowDetail', async (showDetail) => ({...showDetail}))

export const setSelectedIndex = createAsyncThunk('appCustomer/setSelectedIndex', async (selectedIndex) => ({selectedIndex}))

export const setSelectedCustomer = createAsyncThunk('appCustomer/setSelectedCustomer', async (paramData, {
    dispatch,
    getState
}) => {
    dispatch(setShowDetail({showDetail: paramData.showDetail}))
    if (getState().customers?.selectedCustomer?.recno !== paramData?.selectedCustomer?.recno) {
        if (paramData.selectedCustomer) {
            dispatch(setLoading({loadDetail: true}))
            const res = await axios.get(`/Customers/GetDetails/${paramData.selectedCustomer.recno}`)
            dispatch(setLoading({loadDetail: false}))
            return {
                selectedCustomer: res.data.data
            }
        } else {
            return {
                selectedCustomer: paramData.selectedCustomer
            }
        }
    } else {
        return {
            selectedCustomer: getState().customers?.selectedCustomer
        }
    }
})

export const getData = createAsyncThunk('appCustomer/getData', async (params, {dispatch}) => {
    dispatch(setLoading({getTypeData: true}))
    dispatch(setLoading({getData: true}))
    let response;
    if (params.withAmount) {
        response = await axios.get('/Customers/List', {params})
    } else {
        response = await axios.get('/Customers/List', {params})
    }
    dispatch(setLoading({getData: false}))
    return {
        params,
        data: response.data
    }
})

export const getTypeData = createAsyncThunk('appCustomer/getTypeData', async (params, {dispatch}) => {
    dispatch(setLoading({getTypeData: true}))
    const response = await axios.get('/Customers/List', {params})
    dispatch(setLoading({getTypeData: false}))
    return {
        params,
        data: response.data
    }
})

export const getJournal = createAsyncThunk('appCustomer/getJournal', async (recno, {dispatch}) => {
    dispatch(setLoading({getJournal: true}))
    const response = await axios.get(`/Customers/${recno}`)
    dispatch(setLoading({getJournal: false}))

    return response.data
})


export const deleteInvoice = createAsyncThunk('appCustomer/deleteInvoice', async (id, {dispatch, getState}) => {
    dispatch(setLoading({deleteInvoice: true}))
    await axios.delete('/apps/invoice/delete', {id})
    await dispatch(getData(getState().invoice.params))
    dispatch(setLoading({deleteInvoice: false}))
    return id
})


export const getColumns = createAsyncThunk('appCustomer/getColumns', async (params, { dispatch }) => {
  dispatch(setLoading({ getColumns: true }))
  const res = await axios.get('/Prefrences/GetCustomerFormListColumns', { params })
  dispatch(setLoading({ getColumns: false }))
  return {
    params,
    data: res.data??{data: []}
  }
})


export const appCustomerSlice = createSlice({
    name: 'appCustomer',
    initialState: {
        employees: {},
        data: [],
        columns: [],
        totalRecords: 1,
        selectedIndex: 0,
        typeData: [],
        totalTypeRecords: 1,
        params: {},
        typeParams: {},
        selectedCustomer: {},
        loadingState: {getData: false, getTypeData: false, getJournal: false, loadDetail: false}
        // allData: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data.data.list
                state.totalRecords = action.payload.data.totalRecords
                state.params = action.payload.params
            })
            .addCase(getTypeData.fulfilled, (state, action) => {
                state.typeData = action.payload.data.data.list
                state.totalTypeRecords = action.payload.data.totalRecords
                state.typeParams = action.payload.params
            })
            .addCase(getJournal.fulfilled, (state, action) => {
                state.employee = action.payload
            })
            .addCase(setLoading.fulfilled, (state, action) => {
                state.loadingState =  {...state.loadingState, ...action.payload}
            })
            .addCase(setSelectedCustomer.fulfilled, (state, action) => {
                state.selectedCustomer = action.payload.selectedCustomer
            })
            .addCase(setShowDetail.fulfilled, (state, action) => {
                if (!action.payload.showDetail) {
                    state.selectedIndex = 0
                }

                state.showDetail = action.payload.showDetail
            })
            .addCase(getColumns.fulfilled, (state, action) => {
                let cols = [...action.payload.data.data];
                cols.sort((a, b) => a.sortNo - b.sortNo);
                state.columns = cols
            })
            .addCase(setSelectedIndex.fulfilled, (state, action) => {
                state.selectedIndex = action.payload.selectedIndex
            })
    }
})

export default appCustomerSlice.reducer
