// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'

export const setLoading = createAsyncThunk('appCustomerPOSInvoice/setLoading', async (loadingState) => ({ ...loadingState }))

export const getData = createAsyncThunk('appCustomerPOSInvoice/getData', async (params, { dispatch }) => {

  dispatch(setLoading({ getData: true }))

  const response = await axios.get('/SalesInvoices/ConsolidatedList', { params })

  dispatch(setLoading({ getData: false }))

  return {
    params,
    data: response.data,
  }
})


export const appCustomerPOSInvoiceSlice = createSlice({
  name: 'appCustomerPOSInvoice',
  initialState: {
    data: [],
    totalRecords: 1,
    params: {},
    loadingState: { getData: false }
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data?.data?.list??[]
        state.totalRecords = action.payload.data.totalRecords
        state.params = action.payload.params
      })
      .addCase(setLoading.fulfilled, (state, action) => {
        state.loadingState = action.payload
      })
  }
})

export default appCustomerPOSInvoiceSlice.reducer
