// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'

export const setLoading = createAsyncThunk('salesOrdersInvoice/setLoading', async (loadingState) => ({ ...loadingState }))

export const getData = createAsyncThunk('salesOrdersInvoice/getData', async (params, { dispatch }) => {
  dispatch(setLoading({ getData: true }))
  const responseConsolidated = await axios.get('/SalesOrders/ConsolidatedList', { params })
  dispatch(setLoading({ getData: false }))
  return {
    params,
    consolidated: responseConsolidated.data
  }
})

export const getColumns = createAsyncThunk('salesOrdersInvoice/getColumns', async (params, { dispatch }) => {
  dispatch(setLoading({ getColumns: true }))
  const res = await axios.get('/Prefrences/GetSalesEstimateFormListColumns', { params })
  dispatch(setLoading({ getColumns: false }))
  return {
    params,
    data: res.data??{data: []}
  }
})

export const salesOrdersInvoiceSlice = createSlice({
  name: 'salesOrdersInvoice',
  initialState: {
    vendorInvoice: {},
    consolidated: [],
    data: [],
    columns: [],
    totalRecords: 1,
    consolidatedTotalRecords: 1,
    totalTypeRecords: 1,
    params: {},
    loadingState: { getData: false, getTypeData: false, getJournal: false }
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        // console.log(state, action)
        state.data = []
        state.consolidated = action.payload.consolidated.data.list
        state.totalRecords = 0
        state.consolidatedTotalRecords = action.payload.consolidated.totalRecords
        state.params = action.payload.params
      })
      .addCase(getColumns.fulfilled, (state, action) => {
        let cols = [...action.payload.data.data];
        cols.sort((a,b) => a.sortNo - b.sortNo);
        state.columns = cols
      })
      .addCase(setLoading.fulfilled, (state, action) => {
        state.loadingState = {...state.loadingState, ...action.payload}
      })
  }
})

export default salesOrdersInvoiceSlice.reducer
