// ** Redux Imports
import {
  createSlice
  , createAsyncThunk
} from '@reduxjs/toolkit'
import axios from 'axios'

export const getMapAccounts = createAsyncThunk('mapAccounts/getMapAccounts', async () => {
  const response = await axios.get('/MapAccounts')
  const responseDefault = await axios.get('/MapAccounts/GetBranchWiseAccounts')
  return {data: {...response.data.data, ...responseDefault.data.data}}
})

export const mapAccountsSlice = createSlice({
  name: 'mapAccounts',
  initialState: {
    accounts: {}
  },
  reducers: {
  },
  extraReducers: builder => {
    builder
      .addCase(getMapAccounts.fulfilled, (state, action) => {
        state.accounts = action.payload.data
      })
  }

})

export default mapAccountsSlice.reducer
