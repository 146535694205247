// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'

export const setLoading = createAsyncThunk('withHeldTax/setLoading', async (loadingState) => ({ ...loadingState }))

export const getData = createAsyncThunk("withHeldTax/getData", async (params, { dispatch }) => {
  dispatch(setLoading({ getData: true }));
  const response = await axios.get("/Settings/SelectWithHeldTaxSettingsList", { params });

  dispatch(setLoading({ getData: false }));
  return {
    params,
    data: response.data,
  };
});

export const getDetailData = createAsyncThunk("withHeldTax/getDetailData", async (recno, { dispatch }) => {
  dispatch(setLoading({ getDetailData: true }));
  const response = await axios.get(`/Settings/SelectSingleWithHeldTaxSetting/${recno}`);

  dispatch(setLoading({ getDetailData: false }));
  return {
    data: response.data,
  };
});

export const getTaxSetting = createAsyncThunk("withHeldTax/getTaxSetting", async (params, { dispatch }) => {
  dispatch(setLoading({ getTaxSetting: true }));
  const response = await axios.get(`/Settings/SelectOrganizationTaxSettings`);

  dispatch(setLoading({ getTaxSetting: false }));
  return {
    data: response.data,
  };
});

export const withHeldTaxSlice = createSlice({
  name: 'withHeldTax',
  initialState: {
    data: [],
    data_detail: [],
    taxSetting: {},
    totalDetailRecords: 1,
    params: {},
    loadingState: { getData: false, getDetailData: false, getTaxSetting: false}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload?.data?.data?.list
        state.totalRecords = action.payload.data.totalRecords
        state.params = action.payload.params
      })
      .addCase(getDetailData.fulfilled, (state, action) => {
        state.data_detail = action.payload?.data?.data
        state.totalDetailRecords = action.payload.data.totalRecords
      })
      .addCase(getTaxSetting.fulfilled, (state, action) => {
        state.taxSetting = action.payload?.data?.data
      })
      .addCase(setLoading.fulfilled, (state, action) => {
        state.loadingState = action.payload
      })
  }
})

export default withHeldTaxSlice.reducer
