// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'

export const setLoading = createAsyncThunk('appEmployee/setLoading', async (loadingState) => ({ ...loadingState }))

export const getData = createAsyncThunk('appEmployee/getData', async (params, { dispatch }) => {
  dispatch(setLoading({ getData: true }))
  const response = await axios.get('/Employees/List', { params })
  dispatch(setLoading({ getData: false }))
  return {
    params,
    data: response.data
  }
})

export const getTypeData = createAsyncThunk('appEmployee/getTypeData', async (params, { dispatch }) => {
  dispatch(setLoading({ getTypeData: true }))
  const response = await axios.get('/Employees/List', { params })
  dispatch(setLoading({ getTypeData: false }))
  return {
    params,
    data: response.data
  }
})

export const getJournal = createAsyncThunk('appEmployee/getJournal', async (recno, { dispatch }) => {
  dispatch(setLoading({ getJournal: true }))
  const response = await axios.get(`/Employees/${recno}`)
  dispatch(setLoading({ getJournal: false }))

  return response.data
})


export const deleteInvoice = createAsyncThunk('appEmployee/deleteInvoice', async (id, { dispatch, getState }) => {
  dispatch(setLoading({ deleteInvoice: true }))
  await axios.delete('/apps/invoice/delete', { id })
  await dispatch(getData(getState().invoice.params))
  dispatch(setLoading({ deleteInvoice: false }))
  return id
})

export const appEmployeeSlice = createSlice({
  name: 'appEmployee',
  initialState: {
    employees: {},
    data: [],
    totalRecords: 1,
    typeData: [],
    totalTypeRecords: 1,
    params: {},
    typeParams: {},
    loadingState: { getData: false, getTypeData: false, getJournal: false }
    // allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data.data.list
        state.totalRecords = action.payload.data.totalRecords
        state.params = action.payload.params
      })
      .addCase(getTypeData.fulfilled, (state, action) => {
        state.typeData = action.payload.data.data.list
        state.totalTypeRecords = action.payload.data.totalRecords
        state.typeParams = action.payload.params
      })
      .addCase(getJournal.fulfilled, (state, action) => {
        state.employee = action.payload
      })
      .addCase(setLoading.fulfilled, (state, action) => {
        state.loadingState = action.payload
      })
  }
})

export default appEmployeeSlice.reducer
