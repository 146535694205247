// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'
import axios from "axios";
import {tablesDateFormat} from "../utility/Utils";

const config = useJwt.jwtConfig

const initialUser = () => {

  const item = window.localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue

  return item && item !== "undefined" ? JSON.parse(item) : {}
}
export const handleUserProfile = createAsyncThunk('authentication/userProfile', async (params) => {

  try {
    const response = await axios.get('/Login/GetUserProfile')

    const user = {
      ...response?.data?.data, userDetails: undefined, ...response?.data?.data.userDetails
    }

    const options = {
      headers: {
        'Authorization': 'Bearer ' + user.accessToken,
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    }

    const {data: rightsResponse} = await axios.get('/UserRights/GetUserRights', options)

    if (rightsResponse.isSuccessFull) {
      const allAcl = [{
        action: 'view',
        subject: 'DashboardMain'
      }]
      rightsResponse.data?.list?.forEach(right => {
        const acls = []
        if (right.viewScreen) {
          acls.push({action: 'view', subject: right.screenDescription})
          acls.push({action: 'read', subject: right.screenDescription})
        }

        if (right.addScreen) {
          acls.push({action: 'new', subject: right.screenDescription})
          acls.push({action: 'create', subject: right.screenDescription})
        }

        if (right.updateScreen) {
          acls.push({action: 'edit', subject: right.screenDescription})
          acls.push({action: 'update', subject: right.screenDescription})
        }

        if (right.deleteScreen) {
          acls.push({action: 'delete', subject: right.screenDescription})
        }
        allAcl.push(...acls)
      })
      user.ability = allAcl
      delete user.userDetails

      if (params?.ability) {
        params?.ability.update(user.ability)
      }
    }

    return user
  } catch (e) {
    return null;
  }
})

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser(),
    loggedIn: !!initialUser().loginUserId,
    unautorized: false,
    hideState: null
  },
  reducers: {
    // handleMenuHide: (state, action) => {
    //   state.hideState = new Date()
    //   localStorage.setItem('hideState', JSON.stringify(new Date()))
    // },
    // handleMenuShow: (state, action) => {
    //   state.hideState = null
    //   localStorage.removeItem('hideState')
    // },
    handleUpdateUser: (state, action) => {
      state.userData = action.payload
      state.loggedIn = !!action.payload.loginUserId
      state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
      state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
      localStorage.setItem('userData', JSON.stringify(action.payload))
      localStorage.setItem(config.storageTokenKeyName, action.payload.accessToken)
      localStorage.setItem(config.storageRefreshTokenKeyName, action.payload.refreshToken)
    },
    handleLogin: (state, action) => {
      state.userData = action.payload
      state.loggedIn = !!action.payload.loginUserId
      state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
      state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
      localStorage.setItem('userData', JSON.stringify(action.payload))
      localStorage.setItem(config.storageTokenKeyName, action.payload.accessToken)
      localStorage.setItem(config.storageRefreshTokenKeyName, action.payload.refreshToken)
    },
    handleAuthChanged: (state, action) => {
      state.userData = action.payload
      state.loggedIn = !!action.payload.loginUserId
      state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
      state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
      localStorage.setItem('userData', JSON.stringify(action.payload))
      localStorage.setItem(config.storageTokenKeyName, action.payload.accessToken)
      localStorage.setItem(config.storageRefreshTokenKeyName, action.payload.refreshToken)
    },
    handleProfileChanged: (state, action) => {
      let userData = JSON.parse(localStorage.getItem('userData'));
      userData.profileImageURL = action.payload.profileImageURL;
      userData.name = action.payload.name;
      localStorage.setItem('userData', JSON.stringify(userData))
      state.userData = userData;
    },
    handleUnAuthorized: state => {
      state.hideState = null
      state.userData = {}
      state.unautorized = true
      state.loggedIn = false
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('hideState')
      localStorage.removeItem('fYearData')
      localStorage.removeItem('locationData')
      localStorage.removeItem(config.storageTokenKeyName)
      localStorage.removeItem(config.storageRefreshTokenKeyName)
    },
    handleLogout: state => {
      state.hideState = null
      state.userData = {}
      state.loggedIn = false
      state.unautorized = false
      state.sessionOut = false
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('hideState')
      localStorage.removeItem('fYearData')
      localStorage.removeItem('locationData')
      localStorage.removeItem(config.storageTokenKeyName)
      localStorage.removeItem(config.storageRefreshTokenKeyName)
    }
  },
  extraReducers: builder => {
    builder
        .addCase(handleUserProfile.fulfilled, (state, action) => {
          if(action.payload) {
            const user = action.payload;
            state.userData = user
            state.loggedIn = !!user.loginUserId
            state[config.storageTokenKeyName] = user[config.storageTokenKeyName]
            state[config.storageRefreshTokenKeyName] = user[config.storageRefreshTokenKeyName]
            localStorage.setItem('userData', JSON.stringify(user))
            localStorage.setItem(config.storageTokenKeyName, user.accessToken)
            localStorage.setItem(config.storageRefreshTokenKeyName, user.refreshToken)
          } else {
            state.hideState = null
            state.userData = {}
            state.loggedIn = false
            state.unautorized = false
            state[config.storageTokenKeyName] = null
            state[config.storageRefreshTokenKeyName] = null
            // ** Remove user, accessToken & refreshToken from localStorage
            localStorage.removeItem('userData')
            localStorage.removeItem('hideState')
            localStorage.removeItem('fYearData')
            localStorage.removeItem('locationData')
            localStorage.removeItem(config.storageTokenKeyName)
            localStorage.removeItem(config.storageRefreshTokenKeyName)
          }
        })
  }
})

export const { handleLogin, handleUpdateUser, handleAuthChanged, handleProfileChanged, handleUnAuthorized, handleLogout } = authSlice.actions

export default authSlice.reducer
