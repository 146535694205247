// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import {collectChilds, getTotal, getTotalCompare} from "../../../../utility/Utils";

export const setLoading = createAsyncThunk('ProfitReport/setLoading', async (loadingState) => ({...loadingState}))

export const getData = createAsyncThunk('ProfitReport/getData', async (params, {dispatch}) => {
    dispatch(setLoading({getData: true}))
    try {
        let response;
        if (params.LocationId) {
            response = await axios.get('/AccountsReports/LocationProfitAndLossReport', {params})
        } else {
            response = await axios.get('/AccountsReports/ProfitAndLossReport', {params})
        }
        return {
            params,
            data: response.data,
        }
    } catch (e) {
        console.log(e);
        return {
            params,
            data: {
                data: {
                    list: []
                }
            }
        }
    } finally {
        dispatch(setLoading({getData: false}))
    }

})


export const getCompareData = createAsyncThunk('ProfitReport/getCompareData', async (params, {dispatch}) => {
    dispatch(setLoading({getCompareData: true}))
    try {
        let response;
        if(params.compareWith === 'class'){
            response = await axios.get('/AccountsReports/ProfitAndLossCostCenterWiseComparisonReport', {params})
        } else {
            response = await axios.get('/AccountsReports/ProfitAndLossComparisonReport', {params})
        }
        return {
            params,
            data: response.data,
        }
    } catch (e) {
        console.log(e);
        return {
            params,
            data: {
                data: {
                    list: [],
                    columns: []
                }
            }
        }
    } finally {
        dispatch(setLoading({getCompareData: false}))
    }

})

export const ProfitLossStore = createSlice({
    name: 'ProfitReport',
    initialState: {
        data: [],
        compare_data: [],
        compare_columns: [],
        totalRecords: 1,
        params: {},
        compare_params: {},
        loadingState: {getData: false, getCompareData: false}
        // allData: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                let data = action.payload.data.data.list;
                let dataList = [];
                if (data) {
                    data.map((e => {
                        let a = {...e};
                        if (a.accounts) {
                            let totalAmount = 0;
                            a.accounts.map((r => {
                                totalAmount += r.amount;
                                if ((r.childs || []).length > 0) {
                                    r.childAmount = getTotal(r);
                                    totalAmount += r.childAmount;
                                }
                            }))
                            a.amount = totalAmount;
                        }
                        dataList.push(a);
                    }));

                    let incomeList = dataList.filter(e => e.sortLvl === 1);
                    let income = incomeList.length > 0 ? incomeList[0] : null;
                    let costSaleList = dataList.filter(e => e.sortLvl === 2);
                    let costOSale = costSaleList.length > 0 ? costSaleList[0] : null;
                    let expenseList = dataList.filter(e => e.sortLvl === 3);
                    let expense = expenseList.length > 0 ? expenseList[0] : null;
                    let otherExpList = dataList.filter(e => e.sortLvl === 4);
                    let otherExp = otherExpList.length > 0 ? otherExpList[0] : null;
                    let otherIncomeList = dataList.filter(e => e.sortLvl === 5);
                    let otherIncome = otherIncomeList.length > 0 ? otherIncomeList[0] : null;

                    let grossData;
                    if (income && costOSale) {
                        grossData = {
                            headName: "Gross Profit",
                            total: true,
                            amount: costOSale.formula === "-" ? income.amount - costOSale.amount : income.amount - costOSale.amount,
                            accounts: []
                        }
                    } else {
                        grossData = {
                            headName: "Gross Profit",
                            total: true,
                            amount: 0,
                            accounts: []
                        }
                    }

                    dataList.splice(2, 0, grossData)
                    let netProfit = grossData.amount;

                    if (expense) {
                        netProfit -= expense.amount ?? 0;
                    }

                    if (otherExp) {
                        netProfit -= otherExp.amount ?? 0;
                    }

                    if (otherIncome) {
                        netProfit += otherIncome.amount ?? 0;
                    }

                    let netData = {
                        headName: netProfit > -1 ? "Net Profit" : "Net Loss",
                        total: true,
                        net: true,
                        amount: netProfit,
                        accounts: []
                    }

                    dataList.push(netData)

                }
                state.data = dataList
                state.totalRecords = action.payload.data.data.totalRecords
                state.params = action.payload.params
            })
            .addCase(getCompareData.fulfilled, (state, action) => {
                let data = action.payload.data.data.list;
                let dataList = [];
                let columns = action.payload.data.data.columns;


                if (data) {
                    data.map((e => {
                        let a = {...e};
                        if (a.accounts) {
                            a.dynamicColumns = {};
                            for(let i = 0 ; i < columns.length; i++) {
                                let totalAmount = 0;
                                a.accounts.map((r => {
                                    totalAmount += r.dynamicColumns[columns[i]];
                                    if ((r.childs || []).length > 0) {
                                        r.childAmount = getTotalCompare(r, columns[i]);
                                        totalAmount += r.childAmount;
                                    }
                                }))
                                a.dynamicColumns[columns[i]] = totalAmount;
                            }
                        }
                        dataList.push(a);
                    }));

                    let incomeList = dataList.filter(e => e.sortLvl === 1);
                    let income = incomeList.length > 0 ? incomeList[0] : null;
                    let costSaleList = dataList.filter(e => e.sortLvl === 2);
                    let costOSale = costSaleList.length > 0 ? costSaleList[0] : null;
                    let expenseList = dataList.filter(e => e.sortLvl === 3);
                    let expense = expenseList.length > 0 ? expenseList[0] : null;
                    let otherExpList = dataList.filter(e => e.sortLvl === 4);
                    let otherExp = otherExpList.length > 0 ? otherExpList[0] : null;
                    let otherIncomeList = dataList.filter(e => e.sortLvl === 5);
                    let otherIncome = otherIncomeList.length > 0 ? otherIncomeList[0] : null;

                    let grossData;
                    if (income && costOSale) {
                        grossData = {
                            headName: "Gross Profit",
                            total: true,
                            amount: 0,
                            accounts: [],
                            dynamicColumns: {}
                        }

                        for(let i = 0 ; i < columns.length; i++){
                            grossData.dynamicColumns[columns[i]] = costOSale.formula === "-" ? income.dynamicColumns[columns[i]] - costOSale.dynamicColumns[columns[i]] : income.dynamicColumns[columns[i]] - costOSale.dynamicColumns[columns[i]]
                        }

                    } else {
                        grossData = {
                            headName: "Gross Profit",
                            total: true,
                            amount: 0,
                            accounts: [],
                            dynamicColumns: {}
                        }

                        for(let i = 0 ; i < columns.length; i++){
                            grossData.dynamicColumns[columns[i]] = 0
                        }
                    }

                    dataList.splice(2, 0, grossData)

                    let netData = {
                        headName: "Net Profit",
                        total: true,
                        net: true,
                        accounts: [],
                        dynamicColumns: {}
                    };


                    for(let i = 0 ; i < columns.length; i++){
                        let netProfit = grossData.dynamicColumns[columns[i]];

                        if (expense) {
                            netProfit -= expense.dynamicColumns[columns[i]] ?? 0;
                        }

                        if (otherExp) {
                            netProfit -= otherExp.dynamicColumns[columns[i]] ?? 0;
                        }

                        if (otherIncome) {
                            netProfit += otherIncome.dynamicColumns[columns[i]] ?? 0;
                        }

                        netData.dynamicColumns[columns[i]] = netProfit;
                    }

                    dataList.push(netData)
                }

                let detailData = [];

                for(let i = 0 ; i < dataList.length; i++){
                    let list_item = {...dataList[i]}
                    let accounts = list_item?.accounts??[];
                    list_item.accounts = undefined;
                    detailData.push({...list_item});
                    for(let j = 0; j < accounts.length; j++) {
                        let childs = accounts[j].childs??[];
                        accounts[j].childs = undefined;
                        let array = [];
                        for(let k = 0; k < columns.length; k++){
                            array.push(accounts[j].dynamicColumns[columns[k]]);
                        }
                        const isAllZero = array.every(item => item === 0);
                        const cChilds = collectChilds(childs, columns);
                        if(!isAllZero || cChilds.length > 0 ) {
                            detailData.push({...accounts[j]})
                        }

                        if(cChilds.length > 0) {
                            detailData.push(...cChilds)
                        }
                    }
                }

                state.compare_data = detailData
                state.compare_columns = columns
                state.totalRecords = action.payload.data.data.totalRecords
                state.compare_params = action.payload.params
            })
            .addCase(setLoading.fulfilled, (state, action) => {
                state.loadingState = action.payload
            })
    }
})

export default ProfitLossStore.reducer
