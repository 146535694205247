// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'

export const setLoading = createAsyncThunk('projectTransactions/setLoading', async (loadingState) => ({ ...loadingState }))

export const getData = createAsyncThunk('projectTransactions/getData', async (params, { dispatch, getState }) => {
    dispatch(setLoading({ getInvoiceData: true }))
    if (JSON.stringify(params) !== JSON.stringify(getState().projectTransactions?.invoiceParams) || params.forceLoad) {
        try {
            const responseConsolidated = await axios.get('/SalesInvoices/ConsolidatedList', {params})
            dispatch(setLoading({getInvoiceData: false}))
            return {
                params,
                data: responseConsolidated.data
            }
        } catch (e) {
            dispatch(setLoading({getInvoiceData: false}))
            return {
                params,
                data: {data: {list: [], totalRecords: 0}},
            }
        }
    } else {
        dispatch(setLoading({getInvoiceData: false}))
        return {
            params: getState().projectTransactions?.invoiceParams,
            data: {data: {list: getState().projectTransactions?.invoiceData??[], totalRecords: getState().projectTransactions?.invoiceTotal??0}},
        }
    }
})

export const getSalesReturnData = createAsyncThunk('projectTransactions/getSalesReturnData', async (params, { dispatch, getState }) => {
    dispatch(setLoading({ getSalesReturnData: true }))
    try {
        const responseConsolidated = await axios.get('/SalesReturnInvoices/ConsolidatedList', {params})
        dispatch(setLoading({getSalesReturnData: false}))
        return {
            params,
            data: responseConsolidated.data
        }
    } catch (e) {
        dispatch(setLoading({getSalesReturnData: false}))
        return {
            params,
            data: {data: {list: [], totalRecords: 0}},
        }
    }
})

export const getEstimateData = createAsyncThunk('projectTransactions/getEstimateData', async (params, { dispatch, getState }) => {
    dispatch(setLoading({ getEstimateData: true }))
    if (JSON.stringify(params) !== JSON.stringify(getState().projectTransactions?.invoiceParams) || params.forceLoad) {
        try {
            const responseConsolidated = await axios.get('/SalesOrders/ConsolidatedList', {params})
            dispatch(setLoading({getEstimateData: false}))
            return {
                params,
                data: responseConsolidated.data
            }
        } catch (e) {
            dispatch(setLoading({getEstimateData: false}))
            return {
                params,
                data: {data: {list: [], totalRecords: 0}},
            }
        }
    } else {
        dispatch(setLoading({getEstimateData: false}))
        return {
            params: getState().projectTransactions?.estParams,
            data: {data: {list: getState().projectTransactions?.estData??[], totalRecords: getState().projectTransactions?.estTotal??0}},
        }
    }
})

export const getPOData = createAsyncThunk('projectTransactions/getPOData', async (params, { dispatch, getState }) => {
    dispatch(setLoading({ getPOData: true }))
    try {
        const responseConsolidated = await axios.get('/PurchaseOrders/ConsolidatedList', {params})
        dispatch(setLoading({getPOData: false}))
        return {
            params,
            data: responseConsolidated.data
        }
    } catch (e) {
        dispatch(setLoading({getPOData: false}))
        return {
            params,
            data: {data: {list: [], totalRecords: 0}},
        }
    }
})

export const getBillsData = createAsyncThunk('projectTransactions/getBillsData', async (params, { dispatch, getState }) => {
    dispatch(setLoading({ getBillsData: true }))
    try {
        const responseConsolidated = await axios.get('/PurchaseInvoices/ConsolidatedList', {params})
        dispatch(setLoading({getBillsData: false}))
        return {
            params,
            data: responseConsolidated.data
        }
    } catch (e) {
        dispatch(setLoading({getBillsData: false}))
        return {
            params,
            data: {data: {list: [], totalRecords: 0}},
        }
    }
})

export const getBillsReturnData = createAsyncThunk('projectTransactions/getBillsReturnData', async (params, { dispatch, getState }) => {
    dispatch(setLoading({ getBillsReturnData: true }))
    try {
        const responseConsolidated = await axios.get('/PurchaseReturnInvoices/ConsolidatedList', {params})
        dispatch(setLoading({getBillsReturnData: false}))
        return {
            params,
            data: responseConsolidated.data
        }
    } catch (e) {
        dispatch(setLoading({getBillsReturnData: false}))
        return {
            params,
            data: {data: {list: [], totalRecords: 0}},
        }
    }
})

export const getExpensesData = createAsyncThunk('projectTransactions/getExpensesData', async (params, { dispatch, getState }) => {
    dispatch(setLoading({ getExpensesData: true }))
    try {
        const responseConsolidated = await axios.get('/Expenses/ConsolidatedList', {params})
        dispatch(setLoading({getExpensesData: false}))
        return {
            params,
            data: responseConsolidated.data
        }
    } catch (e) {
        dispatch(setLoading({getExpensesData: false}))
        return {
            params,
            data: {data: {list: [], totalRecords: 0}},
        }
    }
})

export const getJournalData = createAsyncThunk('projectTransactions/getJournalData', async (params, { dispatch, getState }) => {
    dispatch(setLoading({ getJournalData: true }))
    try {
        const responseConsolidated = await axios.get('/JV/ConsolidatedList', {params})
        dispatch(setLoading({getJournalData: false}))
        return {
            params,
            data: responseConsolidated.data
        }
    } catch (e) {
        dispatch(setLoading({getJournalData: false}))
        return {
            params,
            data: {data: {list: [], totalRecords: 0}},
        }
    }
})

export const projectTransactionsSlice = createSlice({
    name: 'projectTransactions',
    initialState: {
        invoiceData: [],
        invoiceReturnData: [],
        journalData: [],
        estData: [],
        expData: [],
        billsData: [],
        poData: [],
        billsReturnData: [],
        invoiceTotal: 0,
        invoiceReturnTotal: 0,
        poTotal: 0,
        journalTotal: 0,
        billsTotal: 0,
        billsReturnTotal: 0,
        estTotal: 0,
        expTotal: 0,
        invoiceParams: {},
        invoiceReturnParams: {},
        poParams: {},
        billsParams: {},
        journalParams: {},
        billsReturnParams: {},
        estParams: {},
        expParams: {},
        loadingState: { getInvoiceData: false, getEstimateData: false, getBillsData: false, getExpensesData: false, getSalesReturnData: false, getBillsReturnData: false, getPOData: false, getJournalData: false }
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.invoiceData = action.payload.data.data.list
                state.invoiceTotal = action.payload.data.totalRecords
                state.invoiceParams = action.payload.params
            })
            .addCase(getSalesReturnData.fulfilled, (state, action) => {
                state.invoiceReturnData = action.payload.data.data.list
                state.invoiceReturnTotal = action.payload.data.totalRecords
                state.invoiceReturnParams = action.payload.params
            })
            .addCase(getEstimateData.fulfilled, (state, action) => {
                state.estData = action.payload.data.data.list
                state.estTotal = action.payload.data.totalRecords
                state.estParams = action.payload.params
            })
            .addCase(getExpensesData.fulfilled, (state, action) => {
                state.expData = action.payload.data.data.list
                state.expTotal = action.payload.data.totalRecords
                state.expParams = action.payload.params
            })
            .addCase(getBillsData.fulfilled, (state, action) => {
                state.billsData = action.payload.data.data.list
                state.billsTotal = action.payload.data.totalRecords
                state.billsParams = action.payload.params
            })
            .addCase(getPOData.fulfilled, (state, action) => {
                state.poData = action.payload.data.data.list
                state.poTotal = action.payload.data.totalRecords
                state.poParams = action.payload.params
            })
            .addCase(getBillsReturnData.fulfilled, (state, action) => {
                state.billsReturnData = action.payload.data.data.list
                state.billsReturnTotal = action.payload.data.totalRecords
                state.billsReturnParams = action.payload.params
            })
            .addCase(getJournalData.fulfilled, (state, action) => {
                state.journalData = action.payload.data.data.list
                state.journalTotal = action.payload.data.totalRecords
                state.journalParams = action.payload.params
            })
            .addCase(setLoading.fulfilled, (state, action) => {
                state.loadingState = action.payload
            })
    }
})

export default projectTransactionsSlice.reducer
