// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'

export const setLoading = createAsyncThunk('ItemValuationReport/setLoading', async (loadingState) => ({ ...loadingState }))

export const getData = createAsyncThunk('ItemValuationReport/getData', async (params, { dispatch }) => {
    dispatch(setLoading({ getData: true }))
    let response;
    try {
        response = await axios.get('/StockReports/InventoryAvgValuation', {params})
    }catch (e){
        response = {
            data: {
                data: {
                    list: []
                }
            }
        }
    }finally {
        dispatch(setLoading({ getData: false }))
    }
    return {
        params,
        data: response.data
    }
})


export const getDataLocation = createAsyncThunk('ItemValuationReport/getDataLocation', async (params, { dispatch }) => {
    dispatch(setLoading({ getData: true }))
    let response;
    try {
        response = await axios.get('/StockReports/InventoryAvgValuationLocation', { params })
    }catch (e){
        response = {
            data: {
                data: {
                    list: []
                }
            }
        }
    }finally {
        dispatch(setLoading({ getData: false }))
    }
    return {
        params,
        data: response.data
    }
})

export const ItemValuationReportSlice = createSlice({
    name: 'ItemValuationReport',
    initialState: {
        data: [],
        totalRecords: 1,
        params: {},
        loadingState: { getData: false}
        // allData: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data.data.list
                state.totalRecords = action.payload.data.totalRecords
                state.params = action.payload.params
            })
            .addCase(getDataLocation.fulfilled, (state, action) => {
                state.data = action.payload.data.data.list
                state.totalRecords = action.payload.data.totalRecords
                state.params = action.payload.params
            })
            .addCase(setLoading.fulfilled, (state, action) => {
                state.loadingState = action.payload
            })
    }
})

export default ItemValuationReportSlice.reducer
