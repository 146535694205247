// ** Redux Imports
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios'


export const setLoading = createAsyncThunk('subscriptionInformation/setLoading', async (loadingState) => ({...loadingState}))

export const getData = createAsyncThunk('subscriptionInformation/getData', async (params, {dispatch}) => {
    dispatch(setLoading({getData: true}))
    const response = await axios.get('/OrgPaymentInvoices/GetOrganizationCurrentSubscriptionDetails', params)
    const responsePayment = await axios.get('OrgPaymentInvoices/GetOrganizationCurrentPaymentDetails', params)
    const BillHistory = await axios.get('/OrgPaymentInvoices/List', params)
    dispatch(setLoading({getData: false}))
    return {
        params,
        data: {...response.data.data, ...responsePayment.data.data},
        paymentPlan: BillHistory.data.data.list,

    }
})

export const getCardsDetail = createAsyncThunk('subscriptionInformation/getCardsDetail', async (params, {dispatch}) => {
    dispatch(setLoading({getCardsData: true}))
    const response = await axios.get('/OrgPaymentInvoices/GetStripeRegistedCards', params)
    const responseCurrent = await axios.get('/OrgPaymentInvoices/GetOrganizationCurrentSubscriptionDetails', params)
    const responsePayment = await axios.get('OrgPaymentInvoices/GetOrganizationCurrentPaymentDetails', params)
    dispatch(setLoading({getCardsData: false}))
    return {
        params,
        data: {...responseCurrent.data.data, ...responsePayment.data.data},
        cardsDetail: response.data.data.list,

    }
})

export const appInvoiceSlice = createSlice({
    name: 'subscriptionInformation',
    initialState: {
        data: [],
        paymentPlan: [],
        cardsDetail: [],
        loadingState: {getData: false, getCardsData: false, addingCard: false}

    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getData.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.paymentPlan = action.payload.paymentPlan
        }).addCase(setLoading.fulfilled, (state, action) => {
                state.loadingState = {...(state.loadingState), ...action.payload}
            })
        .addCase(getCardsDetail.fulfilled, (state, action) => {
            state.cardsDetail = action.payload.cardsDetail
            state.data = action.payload.data
        })
    }
})

export default appInvoiceSlice.reducer
