// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'

export const setLoading = createAsyncThunk('appRecurringExpense/setLoading', async (loadingState) => ({ ...loadingState }))

export const getData = createAsyncThunk('appRecurringExpense/getData', async (params, { dispatch }) => {
  dispatch(setLoading({ getData: true }))
  const consolidated_response = await axios.get('/Expenses/RecurringExpensesList', { params: { ...params, ServiceInvoice: false } })
  dispatch(setLoading({ getData: false }))
  return {
    params,
    consolidated: consolidated_response.data
  }
})

export const appRecurringExpenseSlice = createSlice({
  name: 'appRecurringExpense',
  initialState: {
    vendorInvoice: {},
    data: [],
    totalRecords: 1,
    consolidatedTotalRecords: 1,
    typeData: [],
    totalTypeRecords: 1,
    params: {},
    typeParams: {},
    loadingState: { getData: false, getTypeData: false, getJournal: false }
    // allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.consolidated = action.payload.consolidated?.data?.list??[]
        state.consolidatedTotalRecords = action.payload.consolidated?.totalRecords??0
        state.params = action.payload.params
      })
      .addCase(setLoading.fulfilled, (state, action) => {
        state.loadingState = action.payload
      })
  }
})

export default appRecurringExpenseSlice.reducer
