// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'

export const setLoading = createAsyncThunk('customerInvoicePref/setLoading', async (loadingState) => ({ ...loadingState }))

export const getData = createAsyncThunk('customerInvoicePref/getData', async (params, { dispatch }) => {
    dispatch(setLoading({ getData: true }))
    const response = await axios.get('/Prefrences/GetSalesInvoicePrefrences', { params })
    const responseColumns = await axios.get('/Prefrences/GetSalesFormColumns', {})

    dispatch(setLoading({ getData: false }))
    return {
        params,
        data: response.data,
        columns: responseColumns.data
    }
})

export const getCodeCustomization = createAsyncThunk('customerInvoicePref/getCodeCustomization', async (params, { dispatch }) => {
    dispatch(setLoading({ getCodeCustomization: true }))
    const response = await axios.get('/Prefrences/GetDocumentCodesPrefrences', { params })

    dispatch(setLoading({ getCodeCustomization: false }))
    return {
        params,
        data: response.data
    }
})

export const customerInvoicePref = createSlice({
    name: 'customerInvoicePref',
    initialState: {
        data: [],
        columns: [],
        terms: "",
        notes: "",
        codeCustomization: {},
        totalRecords: 1,
        params: {},
        loadingState: { getData: false, getCodeCustomization: false }
        // allData: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data.data.list
                state.columns = (action.payload.columns.data??[]).sort((a, b) => a.columnSeq - b.columnSeq)
                state.terms = action.payload.data.data.terms
                state.notes = action.payload.data.data.notes
                state.totalRecords = action.payload.data.totalRecords
                state.params = action.payload.params
            })
            .addCase(getCodeCustomization.fulfilled, (state, action) => {
                state.codeCustomization = action.payload.data.data
                state.params = action.payload.params
            })
            .addCase(setLoading.fulfilled, (state, action) => {
                state.loadingState = action.payload
            })
    }
})

export default customerInvoicePref.reducer
