// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import {getTotal} from "../../../../utility/Utils";

export const setLoading = createAsyncThunk('BalanceSheet/setLoading', async (loadingState) => ({ ...loadingState }))

export const getData = createAsyncThunk('BalanceSheet/getData', async (params, { dispatch }) => {
    dispatch(setLoading({ getData: true }))
    try {
        let response;
        if (params.LocationId) {
            response = await axios.get('/AccountsReports/LocationBalanceSheetReport', {params})
        } else {
            response = await axios.get('/AccountsReports/BalanceSheetReport', {params})
        }
        return {
            params,
            data: response.data,
        }
    }catch (e){
        console.log(e);
        return {
            params,
            data: {data: {
                list: []
            }}
        }
    }finally {
        dispatch(setLoading({ getData: false }))
    }

})

export const deleteInvoice = createAsyncThunk('BalanceSheet/deleteInvoice', async (id, { dispatch, getState }) => {
    dispatch(setLoading({ deleteInvoice: true }))
    await axios.delete('/apps/invoice/delete', { id })
    await dispatch(getData(getState().invoice.params))
    dispatch(setLoading({ deleteInvoice: false }))
    return id
})

export const BalanceSheetStore = createSlice({
    name: 'BalanceSheet',
    initialState: {
        data: [],
        totalRecords: 1,
        params: {},
        loadingState: { getData: false }
        // allData: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                let data = action.payload.data.data.list;
                let dataList = [];
                if(data){
                    data.map((e => {
                        let a = {...e};
                        if(a.accounts){
                            let totalAmount = 0;
                            a.accounts.map((r => {
                                totalAmount += r.amount;
                                if((r.childs || []).length > 0){
                                    r.childAmount = getTotal(r);
                                    totalAmount += r.childAmount;
                                }
                            }))
                            a.amount = totalAmount;
                        }
                        dataList.push(a);
                    }));

                    let currentAssetsList = dataList.filter(e => e.sortLvl === 1);
                    let cAssets = currentAssetsList.length > 0 ? currentAssetsList[0] : null;

                    let caceList = dataList.filter(e => e.sortLvl === 2);
                    let cashACE = caceList.length > 0 ? caceList[0] : null;

                    let accountRecList = dataList.filter(e => e.sortLvl === 3);
                    let accountRec = accountRecList.length > 0 ? accountRecList[0] : null;

                    let nonCAssetsList = dataList.filter(e => e.sortLvl === 4);
                    let nonCAssets = nonCAssetsList.length > 0 ? nonCAssetsList[0] : null;

                    let fixedAssetsList = dataList.filter(e => e.sortLvl === 5);
                    let fixedAssets = fixedAssetsList.length > 0 ? fixedAssetsList[0] : null;

                    let ownerEqList = dataList.filter(e => e.sortLvl === 6);
                    let ownerEq = ownerEqList.length > 0 ? ownerEqList[0] : null;
                    let ownerEq2 = ownerEqList.length > 1 ? ownerEqList[1] : null;

                    let cLiabilitiesList = dataList.filter(e => e.sortLvl === 7);
                    let cLiabilities = cLiabilitiesList.length > 0 ? cLiabilitiesList[0] : null;

                    let accPayableList = dataList.filter(e => e.sortLvl === 8);
                    let accPayable = accPayableList.length > 0 ? accPayableList[0] : null;

                    let creditCardList = dataList.filter(e => e.sortLvl === 9);
                    let creditCard = creditCardList.length > 0 ? creditCardList[0] : null;

                    let nonCLiabilitiesList = dataList.filter(e => e.sortLvl === 10);
                    let nonCLiabilities = nonCLiabilitiesList.length > 0 ? nonCLiabilitiesList[0] : null;

                    let totalAssetsCount = (cAssets?.amount??0) + (cashACE?.amount??0) + (accountRec?.amount??0) + (nonCAssets?.amount??0) + (fixedAssets?.amount??0)
                    let totalAssets = {
                            headName: "Total Assets",
                            total: true,
                            amount: totalAssetsCount,
                            accounts: []
                    }

                    let spliceIndex = 0;

                    if(cAssets !== null){
                        spliceIndex++;
                    }

                    if(cashACE !== null){
                        spliceIndex++;
                    }

                    if(accountRec !== null){
                        spliceIndex++;
                    }

                    if(nonCAssets !== null){
                        spliceIndex++;
                    }

                    if(fixedAssets !== null){
                        spliceIndex++;
                    }


                    dataList.splice(spliceIndex, 0, totalAssets)

                    let totalLiabilities = 0;

                    if(ownerEq){
                        totalLiabilities += (ownerEq.amount??0);
                    }

                    if(ownerEq2){
                        totalLiabilities += (ownerEq2.amount??0);
                    }

                    if(cLiabilities){
                        totalLiabilities += (cLiabilities.amount??0);
                    }

                    if(accPayable){
                        totalLiabilities += (accPayable.amount??0);
                    }

                    if(creditCard){
                        totalLiabilities += (creditCard.amount??0);
                    }

                    if(nonCLiabilities){
                        totalLiabilities += (nonCLiabilities.amount??0);
                    }

                    let netData = {
                        headName: "Total Liabilities",
                        total: true,
                        net: true,
                        amount: totalLiabilities,
                        accounts: []
                    }

                    dataList.push(netData)

                }
                state.data = dataList
                state.totalRecords = action.payload.data.data.totalRecords
                state.params = action.payload.params
            })
            .addCase(setLoading.fulfilled, (state, action) => {
                state.loadingState = action.payload
            })
    }
})

export default BalanceSheetStore.reducer
